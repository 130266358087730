import React from 'react'

function DayButton({ isSelected, title, onClick }) {
    return (
        <button
            className={`${isSelected ? 'bg-selected-day' : 'bg-day'} py-[6px] px-5 rounded-md`}
            onClick={onClick}>
            <span className='text-white-50 font-medium text-xs leading-5 '>{title}</span>
        </button>
    )
}

export default DayButton