import React from 'react'

function FiltrationButton({isSelected, text , setStatus , status}) {
  return (
    <button onClick={()=>setStatus(status)} className={`2xl:py-5 2xl:px-28 md:py-4 md:px-16 py-3 px-5  ${isSelected? 'bg-second-card text-[#F5F5F5]': 'bg-[#D9D9D9] text-[#757575]'} rounded-md shadow-[0_10px_20px_-7px_rgba(255,255,255,0.2)]`}>
        <p className=' font-medium xl:text-base text-xs leading-4'>
            {text}
        </p>

    </button>
  )
}

export default FiltrationButton