import React from 'react';
import { ArrowDown, ArrowLeft, ArrowRight } from '../Icons/Icons';
import * as ReactDOMServer from "react-dom/server";
import { useTranslation } from 'react-i18next';

function Pagination({ currentPage, totalPages, onPageChange, onPageSizeChange }) {
    const {t}  = useTranslation()
    const svgString = encodeURIComponent(ReactDOMServer.renderToStaticMarkup(<ArrowDown />))

    const pageNumbers = [];
    const pageSizeOptions = [10, 20, 50];

    for (let i = 1; i <= totalPages; i++) {
        pageNumbers.push(i);
    }

    const renderPageNumbers = () => {
        return pageNumbers.map((number) => {
            if (
                number === 1 ||
                number === totalPages ||
                (number >= currentPage - 1 && number <= currentPage + 1)
            ) {
                return (
                    <button
                        key={number}
                        onClick={() => onPageChange(number)}
                        className={`h-10 w-10 flex items-center justify-center rounded-full ${
                            number === currentPage
                                ? 'bg-white text-blue-600'
                                : 'bg-transparent text-white'
                        }`}
                    >
                        {number}
                    </button>
                );
            } else if (
                number === currentPage - 2 ||
                number === currentPage + 2
            ) {
                return <span key={number} className="text-white">...</span>;
            } else {
                return null;
            }
        });
    };

    return (
        <div className='bg-second-card px-2 rounded-xl flex items-center justify-between py-2'>
            <div className="flex items-center gap-2 ml-4">
                <span className="text-white mr-2">{t('show')}</span>
                <select
                    className="text-[#92A0AF] bg-input-second rounded-2xl ps-6 py-4 px-3 flex flex-row-reverse focus:outline-none appearance-none bg-no-repeat bg-[image:var(--image-url)] pe-14"
                    style={{ '--image-url': `url("data:image/svg+xml,${svgString}")` }}
                    onChange={(e) => onPageSizeChange(parseInt(e.target.value))}
                >
                    {pageSizeOptions.map((size) => (
                        <option key={size} value={size}>{size}</option>
                    ))}
                </select>
            </div>
            <div className='flex flex-row-reverse gap-3 items-center'>
            <button
                onClick={() => onPageChange(currentPage - 1)}
                disabled={currentPage === 1}
                className='text-white'
            >
                <ArrowLeft />
            </button>

            <div className='flex flex-row-reverse items-center gap-2'>
                {renderPageNumbers()}
            </div>

            <button
                onClick={() => onPageChange(currentPage + 1)}
                disabled={currentPage === totalPages}
                className='text-white'
            >
                <ArrowRight />

            </button>

            </div>
        </div>
    );
}

export default Pagination;
