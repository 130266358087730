import { CircularProgress } from '@mui/material'
import React from 'react'

export default function LoadingData() {
  return (
    <div className='flex items-center justify-center min-w-max min-h-52'>
        <CircularProgress style={{
            width: '50px',
            height: '50px',
            color: '#fff',
        }} />
    </div>
  )
}
