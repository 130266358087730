import React from 'react';
import { useTranslation } from 'react-i18next';
import { PDF } from '../../../Icons/Icons';
import { CircularProgress, Modal, Rating } from '@mui/material';
import { useMe } from '../../../data/user';
import { useCustomMutation } from '../../../hooks/use-custome-mutation';
import { API_ENDPOINTS } from '../../../client/endpoints';
import client from '../../../client';
import { useParams } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import InputField from '../../../Components/Shared/InputField';
export default function ConsultationCard({ consultationDetails }) {
    const { t } = useTranslation();
    const { me } = useMe()
    const {id} = useParams()
    const [imageModalOpen, setImageModalOpen] = React.useState(false);
    const [rateModalOpen, setRateModalOpen] = React.useState(false);
    const [selectedImage, setSelectedImage] = React.useState('');
    const [error, setError] = React.useState('');
    const handleImageClick = (image) => {
        setSelectedImage(image);
        setImageModalOpen(true);
      };
    
      const handleImageModalClose = () => {
        setImageModalOpen(false);
        setSelectedImage('');
      };
    
    const handleDownload = (fileUrl) => {
        const link = document.createElement('a');
        link.href = fileUrl;
        link.target = '_blank';
        link.setAttribute('download', fileUrl.split('/').pop()); // Set the file name
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    
    const rateMutation = useCustomMutation({
        mutationFn: client.Consultants.rate,
        queryToInvalidate: {
            queryKey: [API_ENDPOINTS.RATE(id), id],
        },
        onSuccess: (data) => {
            setRateModalOpen(false);
        },
        onError: (error) => {
            setError(error.message)
        }
    })
    let Formik = useFormik({
        initialValues: {
          value: 0,
          comment: ''
        },
        validationSchema: Yup.object().shape({
          value: Yup.number()
            .min(1, t('Minimum rating is 1'))
            .max(5, t('Maximum rating is 5'))
            .required(t('التقيم مطلوب')),
          comment: Yup.string()
        }),
        onSubmit: (values) => {
          rateMutation.mutate({
            value: values.value,
            id,
            comment: values.comment
          });
        }
      });
    return (
        <>
            <h1 className='text-white text-xl font-bold leading-5'>{t('consultation_details')}</h1>
            <div className='bg-second-card xl:px-11 px-3 py-5 flex flex-col gap-7 rounded-lg'>
                {/* image name and myConsultation badge */}
                <div className='flex justify-between'>
                    <div className='flex flex-row gap-3'>
                        <div className='w-10 h-10 rounded-full overflow-hidden'>
                            <img className='w-full h-full' src={consultationDetails?.lawyer?.profileImage?consultationDetails?.lawyer?.profileImage:consultationDetails?.client?.profileImage} alt="" />
                        </div>
                        <div>
                            <p className='text-main text-xs font-bold leading-5'>{consultationDetails?.lawyer?.name?consultationDetails?.lawyer?.name:consultationDetails?.client?.name}</p>
                            <p className='text-white text-[10px] font-medium leading-5'>{consultationDetails?.client?.name ? t('client') : t('lawyer')}</p>
                        </div>
                    </div>
                    {(consultationDetails.status==='finished' && me?.type ==='client' && !consultationDetails.rate.value ) ?
                    <button onClick={()=> setRateModalOpen(true)} className={`btn bg-input-second font-bold text-main md:px-10 md:py-3 px-5 py-1 rounded-xl`}>
                        {t('rate_consultant')}
                    </button>
                    :                
                    <button className={`btn bg-input-second text-main md:px-10 md:py-3 px-5 py-1 rounded-xl`}>
                        <p className='font-bold'>{consultationDetails?.type === 'reservation' ? t('reservation') : t('consultant')}</p>
                    </button>
                    }
                </div>
                {/* description */}
                <div>
                    <h3 className='text-white pb-2'>{t('description')}</h3>
                    <div className='pb-9 pt-4 xl:px-10 px-2 bg-input-second rounded-xl'>
                        <p className='text-white xl:w-[35%] w-full text-xs leading-5'>
                            {consultationDetails?.description}
                        </p>
                    </div>
                </div>
                {/* files */}
                <div className='flex flex-col text-white gap-2'>
                    {consultationDetails?.attachments?.map((file, index) => (
                        file.includes('pdf') ?
                            <div className='flex gap-4' key={index}>
                                <div className='w-10 h-10 rounded-xl overflow-hidden'>
                                    <PDF />
                                </div>
                                <div className='flex justify-between items-center w-4/5 py-1'>
                                    <div>{file?.split('consultations/')[1]}</div>
                                    <button className='text-main' onClick={() => handleDownload(file)}>{t('download')}</button>
                                </div>
                            </div> :
                            <div className='flex items-center gap-4' key={index}>
                                <div className='w-10 h-10 rounded-xl overflow-hidden'>
                                    <img src={file} className='w-full h-full' alt='' />
                                </div>
                                <div className='flex justify-between items-center w-4/5 py-1'>
                                    <div>ملف قضية  {consultationDetails?.lawyer?.name?consultationDetails?.lawyer?.name:consultationDetails?.client?.name}</div>
                                    <button onClick={() => handleImageClick(file)} className='text-main pe-[6px]'>{t('show')}</button>
                                </div>
                            </div>
                    ))}
                </div>
                <Modal open={imageModalOpen} onClose={handleImageModalClose}>
                    <div className="absolute overflow-y-auto top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 p-5 max-h-[80vh] bg-white rounded-lg shadow-lg">
                        <img src={selectedImage} alt="" className="w-full h-full" />
                    </div>
                </Modal>
                <Modal open={rateModalOpen} onClose={()=>setRateModalOpen(false)}>
                    <div dir='rtl' className="absolute bg-second-card text-white overflow-y-auto top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 p-5 max-h-[80vh] w-96 bg-white rounded-lg shadow-lg">
                    <form className='flex flex-col gap-4' onSubmit={Formik.handleSubmit}>
                        <Rating name="value"
                        onBlur={Formik.handleBlur}
                        onChange={Formik.handleChange}
                        value={Formik.values.value}
                        />
                        <InputField
                            name='comment'
                            label={t('input_comment_rate')}
                            onBlur={Formik.handleBlur}
                            onChange={Formik.handleChange}
                            value={Formik.values.comment}
                        />
                    {error && <div className='text-red-600 text-center'>{error}</div>}
                    <div className='flex justify-center'>
                        <button className={`btn bg-main text-dark px-7 py-4 xl:w-[30%] w-[60%] rounded-xl`} type='submit'>
                            {rateMutation.isPending ? <CircularProgress style={{
                                width: '20px',
                                height: '20px',
                                color: '#fff',
                            }} /> : <p className='font-bold'> {t('rate')} </p>}
                        </button>
                    </div>

                        </form>
                    </div>
                </Modal>

            </div>
            
        </>
    );
}
