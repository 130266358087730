import React from 'react'

import achieveClientCount from '../../../assets/images/achieveClientCount.svg';
import achieveLawyerCount from '../../../assets/images/achieveLaywersCount.svg';
import achieveConsultCount from '../../../assets/images/achieveConsultCount.svg';

import achievementBg from '../../../assets/images/achievement.jpg';
import { useTranslation } from 'react-i18next';
import AchievementCart from './AchievementCart';

const achievementStats = [{
    image: achieveLawyerCount,
    title: 'عدد المحامين لدينا',
    count: 300
},
{
    image: achieveClientCount,
    title: 'عدد الموكلين',
    count: 500
},
{
    image: achieveConsultCount,
    title: 'عدد الإستشارات',
    count: 956
}]

function AchievementsSection() {
    const { t } = useTranslation();
    return (
        <div id="achievements" className='h-full bg-cover bg-top' style={{ backgroundImage: `url(${achievementBg})` }}>
            <div className="bg-[#141418]/[0.4] h-full pb-1">
                <div className='container mx-auto'>
                    {/* symbol */}
                    <div className='flex justify-center items-center py-10'>
                        <div className='w-0 border-main h-20'></div>
                        <div className='w-0 border-main h-20 mx-7'></div>
                        <div className='w-0 border-main h-20'></div>
                    </div>

                    {/* text */}
                    <div className='flex justify-center items-center flex-col text-center'>
                        <h2 className='md:text-4xl text-2xl font-semibold text-white'>{t('top_achievements')}</h2>
                        <h2 className='md:text-4xl text-2xl font-semibold text-white my-10'>{t('numbers_and_stats')}</h2>
                        <p className='xl:w-[39%] lg:w-[60%] text-center text-white md:text-lg text-xs mt-6 sm:mx-10 mx-3 md:mx-0'>{t('achievements_description')}</p>
                    </div>

                    {/* stats */}
                    <div className='grid grid-rows-1 lg:grid-cols-3 my-10 lg:my-20 gap-5'>
                        {achievementStats.map((stat, index) => <AchievementCart key={index} title={stat.title} number={stat.count} icon={stat.image} />)}
                    </div>
                </div>
            </div>

        </div>
    )
}

export default AchievementsSection