import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import LawyerCard from '../../../Components/Home/Lawyers/LawyerCard';
import { useUsers } from '../../../data/vistor';
import Pagination from '../../../Components/Pagination';
import { Search } from '../../../Icons/Icons';
import LoadingData from '../../../Components/Shared/LoadingData';
import NotFoundData from '../../../Components/Shared/NotFoundData';

function Lawyers() {
    const [searchTerm, setSearchTerm] = React.useState('');
    const [search, setSearch] = React.useState('');
      const [pagination, setPagination] = useState({
        pageIndex: 1,
        pageSize: 12,
    });
    const [userData, setUserData] = useState([]);
    const { t } = useTranslation();
    const { data: fetchedUsers = {}, isPending: isLoadingUsers } = useUsers({
        page: pagination.pageIndex,
        limit: pagination.pageSize,
        search:search===''? null:search,
        type: 'lawyer',
    });

    React.useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
          setSearch(searchTerm);
        }, 300);
    
        return () => clearTimeout(delayDebounceFn);
      }, [searchTerm]);
    
    const handlePageChange = (newPageIndex) => {
        setPagination((prev) => ({
            ...prev,
            pageIndex: newPageIndex,
        }));
    };

    const handlePageSizeChange = (newPageSize) => {
        setPagination((prev) => ({
            ...prev,
            pageSize: newPageSize,
            pageIndex: 1, // Reset to first page when changing page size
        }));
        setUserData([]); // Clear the current data
    };

    React.useEffect(() => {
        if (fetchedUsers.data) {
            setUserData(fetchedUsers.data);
        }
    }, [fetchedUsers.data]);

    return (
        <div className='bg-second h-full py-16'>
            <div className='container mx-auto'>
                <h1 className='text-white text-xl font-bold leading-5 mb-5'>{t('our_lawyers')}</h1>
                <div className="relative">
                    <div className="relative mb-2">
                        <div className="absolute inset-y-0 start-0 flex items-center ps-3.5 pointer-events-none">
                            <Search />
                        </div>
                        <input
                            type="text"
                            onChange={(e) => setSearchTerm(e.target.value)}
                            className="input bg-second-card font-medium text-white placeholder:text-[#7B8389] rounded-xl block w-full ps-12 p-4"
                            placeholder={t('search_on_lawyer')}
                        />
                    </div>
                </div>    
                {isLoadingUsers ? <LoadingData/>:
                userData.length>0?
                <div className='grid grid-cols-4 gap-x-16 gap-y-8 py-8'>
                    {userData.map((lawyer, index) => {
                        return (
                            <LawyerCard 
                            key={index} 
                            lawyer={lawyer}
                        />)
                    })}
                </div>
                :<NotFoundData Message={t('Not_Found_Lawyers')}/>}

                <Pagination
                    currentPage={pagination.pageIndex}
                    totalPages={fetchedUsers.pagination?.totalPages || 1}
                    onPageChange={handlePageChange}
                    onPageSizeChange={handlePageSizeChange}
                />
            </div>
        </div>
    );
}

export default Lawyers;
