import { FormControlLabel} from '@mui/material'
import React from 'react'
import Plan from '../../../Components/Lawyer/SubscriptionPlans/Plan/Plan';
import { useTranslation } from 'react-i18next';
import IOSSwitch from '../../../Components/Lawyer/SubscriptionPlans/IOSSwitch';
import { usePlans } from '../../../data/vistor';
import { useMe } from '../../../data/user';
import NotFoundData from '../../../Components/Shared/NotFoundData';
import LoadingData from '../../../Components/Shared/LoadingData';


function SubscriptionPlans() {
    const {me} = useMe()
    const {data , isPending} = usePlans()
    const [checked, setChecked] = React.useState(false);
    const {t} = useTranslation();

    const handleChange = (event) => {
        setChecked(event.target.checked);
    };
    return (
        me?.isVerifiedByCrm?

            <div className='pt-16 h-full'>
                {/* header */}
                <div className='text-center text-white-50'>
                    <h1 className='text-4xl font-bold '>{t('lawyer_advantages')}</h1>
                    <p className='text-xl py-10'>{t('choose_plan')}</p>
                </div>
                <div className='font-dm text-base flex fle-col text-white-50 content-center justify-center justify-items-center'>
                    <p>{t('monthly_payment')}</p>
                    <FormControlLabel
                        sx={{ marginRight: 4, marginLeft: 4 }}
                        control={<IOSSwitch defaultChecked checked={checked} onChange={handleChange} />}
                    />
                    <p>{t('yearly_payment')}</p>
                </div>
                <div className=' py-12 xl:px-[10%] px-[5%] grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-5 content-center justify-center justify-items-center'>
                {isPending ? <LoadingData/>:
                data.length>0?
                    data.map((plan)=>{
                        return <Plan key={plan.id} plan={plan} />
                    }):
                    <NotFoundData Message={t('Not_Found_Plans')}/>
                    }
                    {/* <Plan />
                    <Plan isMiddle={true} />
                    <Plan /> */}
                </div>
            </div>:
                <div className='flex items-center justify-center h-[80vh]'>
                    <h2 className='text-3xl text-main'>{t('account_under_review')}</h2>
                </div>
    )
}

export default SubscriptionPlans