import { Rating } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'

export default function InfoCard({user}) {
    const {t} = useTranslation()
  return (
    <div className='flex gap-6'>
        <div className='w-[150px] h-[120px] rounded-lg overflow-hidden'>
            <img src={user?.profileImage} className='w-full h-full' alt={user?.name} />
        </div>
        <div className='flex flex-col gap-6 text-white'>
            <div className='flex flex-col'>
                <h2 className='font-medium py-1'>{user?.name}</h2>
                <Rating name="read-only" value={user?.ratingsCount} readOnly className='ml-auto' />
            </div>
            <div className='flex gap-[6px]'>
                <div className='flex flex-col items-center justify-center px-2'>
                    <div>{t('available_to_call')}</div>
                    <div className='text-main font-medium'>{user?.isAvailableToCall?t('yes'):t('no')}</div>
                </div>
                <div className='flex flex-col items-center justify-center px-2'>
                    <div>{t('available_to_visit')}</div>
                    <div className='text-main font-medium'>{user?.isAvailableToVisit?t('yes'):t('no')}</div>
                </div>
                {user?.city && 
                <div className='flex flex-col items-center justify-center px-2'>
                    <div>{t('location')}</div>
                    <div className='text-main font-medium'>{user?.city}</div>
                </div>
                }
                {user?.rank && 
                <div className='flex flex-col items-center justify-center px-2'>
                    <div>{t('degree_of_restriction')}</div>
                    <div className='text-main font-medium'>{user?.rank}</div>
                </div>
                }
            </div>
        </div>
    </div>  )
}
