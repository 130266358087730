import { Formik, Field, Form, ErrorMessage } from 'formik';
import React from 'react'
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup'
import { useCustomMutation } from '../../../hooks/use-custome-mutation';
import client from '../../../client';
import { CircularProgress } from '@mui/material';


const validationSchema = Yup.object({
    email: Yup.string().required('البريد الإلكتروني مطلوب').email('يجب عليك كتابة بريد الإلكتروني صحيحاً'),
    message: Yup.string().required('الرسالة مطلوبة'),
    phoneNumber: Yup.string().required('رقم الهاتف مطلوبة'),
});
function HomeForm() {
    const { t } = useTranslation();
    const [success, setSuccess] = React.useState('')
    const [error, setError] = React.useState('')
    const TicketMutation = useCustomMutation({
        mutationFn: client.Tickets.post_ticket,
        onSuccess: (data) => {
            setSuccess('تم الارسال بنجاح')
            setTimeout(() => {
                setSuccess('')
            }, 5000)
        },
        onError: (error) => {
            setError('حدث خطأ ما, الرجاء المحاولة لاحقاً')
            setTimeout(() => {
                setError('')
            }, 5000)
        },
    });
    return (
        <div>
            <Formik
                initialValues={{ email: '', message: '',phoneNumber:'' }}
                validationSchema={validationSchema}
                onSubmit={(values, { resetForm }) => {
                    const data = {
                        email: values.email,
                        message: values.message,
                        phoneNumber: values.phoneNumber
                    }
                    TicketMutation.mutate(data);
                    resetForm();
                }}
            >
                <Form className='w-[90%] mx-auto'>
                    <label htmlFor="email" className="block mb-2 text-sm text-muted">{t('input_email')}</label>
                    <Field type="email" name='email' className="focus:outline-none text-black text-sm rounded-lg block w-full p-4 bg-input-second" autocomplete="off"/>
                    <ErrorMessage name='email' component='div' className='text-red-700 mt-2' />

                    <label htmlFor="phoneNumber" className="block mb-2 mt-5 text-sm text-muted">{t('input_phone')}</label>
                    <Field type="text" name='phoneNumber' className="focus:outline-none text-black text-sm rounded-lg block w-full p-4 bg-input-second" autocomplete="off"/>
                    <ErrorMessage name='phoneNumber' component='div' className='text-red-700 mt-2' />

                    <label htmlFor="message" className="block mb-2 text-sm text-muted mt-5">{t('your_message')}</label>
                    <Field type="text" as="textarea" rows="10" name='message' className="focus:outline-none text-black text-sm rounded-lg block w-full p-4 bg-input-second" autocomplete="off"/>
                    <ErrorMessage name='message' component='div' className='text-red-600' />
                    {/* {error !== '' && <div className='text-red-600 text-center'>{error}</div>} */}
                    {/* {success !== '' && <div className='text-green-600 text-center'>{success}</div>} */}

                    <div className={` text-center py-6 ${(error === '' && success === '')? 'text-[#193956]' : 'text-white'}`}>
                        <h4>{(error === '' && success === '')? 'none' : error + success}</h4>
                    </div>
                    <div className='mb-9'>
                        <button className="w-full bg-main rounded-2xl py-5 font-semibold" type='submit'>
                            {TicketMutation.isPending ? <CircularProgress style={{
                                width: '20px',
                                height: '20px',
                                color: '#fff',
                            }} /> : t('send')}
                        </button>
                    </div>
                </Form>
            </Formik>
        </div >
    )
}

export default HomeForm