import React from 'react'
import { useTranslation } from 'react-i18next';
const convertDays = {
    "sunday": "الأحد",
    "monday": "الإثنين",
    "tuesday": "الثلاثاء",
    "wednesday": "الأربعاء",
    "thursday": "الخميس",
    "friday": "الجمعة",
    "saturday": "السبت"
  };
  
export default function TimeWork({time}) {
    const { t } = useTranslation();
    return (
        <div  className='flex gap-4 items-center'>
            <div className='text-main font-medium'>{convertDays[time.day]}</div>
            <div className='flex gap-7'>
                {time.periods && time.periods.map((period) =>
                <div key={period._id} className='p-[10px] rounded-lg flex items-center justify-center border-main text-sm font-bold'>
                    {t('from')} {period.fromTime} {t('to')} {period.toTime}
                </div>
                )}
            </div>
        </div>)
}
