import React from 'react'
import { TimeFormate } from '../../../../data/TimeFormate'
import { Link } from 'react-router-dom'
import NotificationImage from '../../../../assets/images/notificationImage.png';
function NotificationItem({ item }) {
    const formate = TimeFormate(item.createdAt)    
    
    return (
        item.target?
        <Link to={`/my-request/${item.target._id}`} className="bg-second-card pb-8 pt-10 pl-6 pr-16 rounded-md relative">
            {/* image */}
            <div className='w-10 h-10 rounded-full absolute overflow-hidden top-2 right-2'>
                <img src={NotificationImage} className='w-full h-full' alt="" />
            </div>
            {/* title */}
            <div className=' absolute top-2 right-16'>
            <h3 className='text-main text-[10px] font-bold leading-5'>{item.title}</h3>
            </div>
            {/* description and availability */}
            <div className='flex justify-between'>
                <h3 className='text-white text-xs font-semibold leading-5'>{item.body}</h3>
                <div className={`w-5 h-5 rounded-full ${!item.seen ? 'bg-[#10B981]' : 'hidden'}`}></div>
            </div>
            {/* time */}
            <div className='absolute bottom-1 left-9'>
                <p className=' text-white text-[10px] leading-5'>{`${formate.dayText} ${formate.formattedDate}`}</p>
            </div>
        </Link>:
        <div className="bg-second-card pb-8 pt-10 pl-6 pr-16 rounded-md relative">
            {/* image */}
            <div className='w-10 h-10 rounded-full absolute overflow-hidden top-2 right-2'>
                <img src={NotificationImage} className='w-full h-full' alt="" />
            </div>
            {/* title */}
            <div className=' absolute top-2 right-16'>
            <h3 className='text-main text-[10px] font-bold leading-5'>{item.user.name}</h3>
            </div>
            {/* description and availability */}
            <div className='flex justify-between'>
                <h3 className='text-white text-xs font-semibold leading-5'>{item.body}</h3>
                <div className={`w-5 h-5 rounded-full ${!item.seen ? 'bg-[#10B981]' : 'hidden'}`}></div>
            </div>
            {/* time */}
            <div className='absolute bottom-1 left-9'>
                <p className=' text-white text-[10px] leading-5'>{`${formate.dayText} ${formate.formattedDate}`}</p>
            </div>
        </div>
    )
}

export default NotificationItem