import React from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useUser } from "../../../data/vistor";
import { Share } from "@mui/icons-material";
import ShareLink from "../../Shared/ShareLink";

export default function Lawyer() {
    const {t} = useTranslation()
    const [openModel, setOpenModel] = React.useState(false)
    const {id} = useParams();
    const userData = useUser(id)
    const shareUrl = `${process.env.REACT_APP_DOMAIN}/lawyer/${id}`
  return (
    <div className="pt-10 pb-12 container mx-auto">
      {/* image */}
      <div className="flex flex-col gap-9">
      <div className='bg-second-card border-white border-2 rounded-xl py-7 relative'>
            <div className='flex justify-center items-center '>
                <div className='relative w-[100px] h-[100px]'>
                   <img className='w-full h-full  rounded-full border-white border ' src={userData.data?.profileImage} alt="" />
                </div>
            </div>
            <div className="absolute top-3 left-3">
              <Share onClick={()=>setOpenModel(true)} className="text-white cursor-pointer"/>
            </div>
        </div>
        <div className="flex flex-col gap-12">
          {/* Main Information */}
          <div className="bg-second-card border-white border-2 rounded-xl py-8 md:px-11 px-5 text-white">
            <div className="flex flex-col gap-5">
              <h2 className="font-semibold leading-5">
                {t("main_information")}
              </h2>
              <div className="flex flex-col gap-[10px]">
                <div className="grid lg:grid-cols-2 grid-cols-1 gap-4">
                  <div className="flex flex-col gap-2 styled-select">
                    <label className="leading-5 text-sm">
                    {t("input_name")}
                    </label>
                    <div className="text-[#92A0AF] bg-input-second rounded-2xl ps-6 py-4 px-3 flex focus:outline-none">
                      {userData.data?.name}
                    </div>
                  </div>
                  <div className="flex flex-col gap-2 styled-select">
                    <label className="leading-5 text-sm">
                    {t("input_email")}
                    </label>
                    <div className="text-[#92A0AF] bg-input-second rounded-2xl ps-6 py-4 px-3 flex focus:outline-none">
                      {userData.data?.email}
                    </div>
                  </div>
                  <div className="flex flex-col gap-2 styled-select">
                    <label className="leading-5 text-sm">
                    {t("input_phone")}
                    </label>
                    <div className="text-[#92A0AF] bg-input-second rounded-2xl ps-6 py-4 px-3 flex focus:outline-none">
                      {userData.data?.phoneNumber}
                    </div>
                  </div>
                  <div className="flex flex-col gap-2 styled-select">
                    <label className="leading-5 text-sm">
                    {t("input_registration_degree")}
                    </label>
                    <div className="text-[#92A0AF] bg-input-second rounded-2xl ps-6 py-4 px-3 flex focus:outline-none">
                      {userData.data?.rank}
                    </div>
                  </div>
                  <div className="flex flex-col gap-2 styled-select">
                    <label className="leading-5 text-sm">
                    {t("input_governorate")}
                    </label>
                    <div className="text-[#92A0AF] bg-input-second rounded-2xl ps-6 py-4 px-3 flex focus:outline-none">
                      {userData.data?.governorate}
                    </div>
                  </div>
                  <div className="flex flex-col gap-2 styled-select">
                    <label className="leading-5 text-sm">
                    {t("input_city")}
                    </label>
                    <div className="text-[#92A0AF] bg-input-second rounded-2xl ps-6 py-4 px-3 flex focus:outline-none">
                      {userData.data?.city}
                    </div>
                  </div>
                  <div className="flex flex-col gap-2 styled-select">
                    <label className="leading-5 text-sm">
                    {t("available_to_visit")}
                    </label>
                    <div className="text-[#92A0AF] bg-input-second rounded-2xl ps-6 py-4 px-3 flex focus:outline-none">
                      {userData.data?.isAvailableToVisit ===true ? t('yes'):t('no')}
                    </div>
                  </div>
                  <div className="flex flex-col gap-2 styled-select">
                    <label className="leading-5 text-sm">
                    {t("available_to_call")}
                    </label>
                    <div className="text-[#92A0AF] bg-input-second rounded-2xl ps-6 py-4 px-3 flex focus:outline-none">
                      {userData.data?.isAvailableToCall ===true ? t('yes'):t('no')}
                    </div>
                  </div>
                  <div className="flex flex-col gap-2 styled-select">
                    <label className="leading-5 text-sm">
                    {t("main_specialties")}
                    </label>
                    <div className="text-[#92A0AF] bg-input-second rounded-2xl ps-6 py-4 px-3 flex focus:outline-none">
                      {userData.data?.mainSpecialty?.value}
                    </div>
                  </div>
                  <div className="flex flex-col gap-2 styled-select">
                    <label className="leading-5 text-sm">
                    {t("specialties")}
                    </label>
                    <div className="text-[#92A0AF] bg-input-second rounded-2xl ps-6 py-4 px-3 focus:outline-none grid grid-cols-4">
                      {userData.data?.specialties?.map((item , index)=>
                        <div key={index}>{item.value}</div>
                      )}
                    </div>
                  </div>
                  <div className="flex flex-col gap-2 styled-select">
                    <label className="leading-5 text-sm">
                    {t("input_consultation_call_price")}
                    </label>
                    <div className="text-[#92A0AF] bg-input-second rounded-2xl ps-6 py-4 px-3 flex focus:outline-none">
                      {userData.data?.price?.call}
                    </div>
                  </div>
                  <div className="flex flex-col gap-2 styled-select">
                    <label className="leading-5 text-sm">
                    {t("input_consultation_visit_price")}
                    </label>
                    <div className="text-[#92A0AF] bg-input-second rounded-2xl ps-6 py-4 px-3 flex focus:outline-none">
                      {userData.data?.price?.others}
                    </div>
                  </div>
                  <div className="flex flex-col gap-2 styled-select">
                    <label className="leading-5 text-sm">
                    {t("about_me")}
                    </label>
                    <div className="text-[#92A0AF] bg-input-second rounded-2xl ps-6 py-4 px-3 flex focus:outline-none">
                      {userData.data?.about}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ShareLink openModel={openModel} setOpenModel={setOpenModel} shareUrl={shareUrl}/>
    </div>
  );
}
