import React from 'react'
import Navbar from '../Shared/Navbar/Navbar'
import FooterComponent from '../Shared/Footer/Footer'
import { Outlet, useLocation } from 'react-router-dom'

export default function Layout() {
  const location = useLocation()
  const pathName =  location.pathname
  return (
    <>
    <Navbar />
    <div className={`${pathName==='/'? '':'pt-[80px] lg:pt-[100px]'} bg-second  min-h-screen`}>
      <Outlet/>
    </div>
    <FooterComponent />

    </>


)
}
