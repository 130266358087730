import React from 'react'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { useNavigate } from 'react-router-dom';
import { API_ENDPOINTS } from '../../../client/endpoints';
import client from '../../../client';
import { CircularProgress } from '@mui/material';
import { useCustomMutation } from '../../../hooks/use-custome-mutation';
import { useTranslation } from 'react-i18next';
import { User} from '../../../Icons/Icons';
import Input from '../../../Components/Auth/Forms/Input';

export default function RecoveryAccount() {
  const { t } = useTranslation();
  const [error, setError] = React.useState('')
  let navigate = useNavigate()

  let validationSchema = Yup.object({
    user:Yup.string().required(),
  })
  const recoveryMutation = useCustomMutation({
    mutationFn: client.auth.recovery,
    queryToInvalidate: {
      queryKey: [API_ENDPOINTS.GET_PROFILE],
    },
    onSuccess: () => {
        navigate(`/auth/otp?user=${Formik.values.user}`);
    },
    onError: (data) => {
      setError(data.message)
      setTimeout(() => {
        setError('')
      }, 5000)
    },
  });

  let Formik = useFormik({
    initialValues:{
      user:'',
    },
    validationSchema,
    onSubmit:(values)=>{
      // navigate('/')
      recoveryMutation.mutate(values)
    }
  })

  return (
    <div className='flex flex-col'>
  <form onSubmit={Formik.handleSubmit} className='flex flex-col gap-y-8 lg:p-8 lg:w-[480px]'>
      <h1 className='font-semibold text-[28px] text-white text-center leading-[48px] tracking-tight'>{t('recovery_auth_message')}</h1>
      <div className='text-[#FBF3E9] w-4/5'>{t('recovery_sub_auth_message')}</div>
      <Input 
        onBlur={Formik.handleBlur} 
        onChange={Formik.handleChange}
        value={Formik.values.user}
        name={'user'}
        label={t('input_email')}
        rightIcon={<User/>}
        placeholder={t('placeholder_email')}
        IsError={Formik.errors.user && Formik.touched.user} 
        error={Formik.errors.user}  
      />      
      <div>
         {error && <div className='text-red-500 text-center'>{error}</div>}
         <button type='submit' disabled={!(Formik.isValid && Formik.dirty ) || recoveryMutation.isPending} className='bg-main font-semibold text-second w-full px-2 py-3 rounded-xl'>
           {recoveryMutation.isPending?<CircularProgress style={{
             width: '20px',
             height: '20px',
             color: '#fff',
           }} />:t('continue')}</button>
      </div>
  </form>
    </div>

  )
}
