import mainSectionBg from '../../assets/images/mainsection.jpg';

import MainSection from '../../Components/Home/MainSection/MainSection';
import Services from '../../Components/Home/Services/Services';
import AboutSection from '../../Components/Home/About/AboutSection';
import Lawyers from '../../Components/Home/Lawyers/Lawyers';
import AchievementsSection from '../../Components/Home/Achievements/AchievementsSection';
import FormSection from '../../Components/Home/Form/FormSection';
import { useAuth } from '../../Components/Auth/context';
import { useMe } from '../../data/user';
import { useAdvertisement } from '../../data/vistor';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 3000,
};

export default function Home() {
  const { isAuthorized } = useAuth();
  const {data} = useAdvertisement();
  const {me} = useMe();
  return (
    <div>

      {/* first section */}
      <div className='relative pt-[80px]' >
        {/* navbar */}
        {isAuthorized ? 
        <Slider {...settings} className='absolute inset-0 overflow-hidden'>
          {data.map((item, index) => (
            <div key={index}>
              <div
                className='blur-[2px] transform bg-cover bg-center' 
                style={{ backgroundImage: `url(${item.image})` , height: 'calc(100vh + 180px)' }}
              />
            </div>
          ))}
        </Slider>
        :<div className='blur-[2px] absolute inset-[0px] overflow-hidden transform  bg-cover bg-center' style={{ backgroundImage: `url(${mainSectionBg})` }} /> 
        }

        {/* main section */}
        <MainSection />
      </div>

      {/* services section */}
      <Services />

      {/* our lawyers */}
      {isAuthorized && me?.type === 'client' ? <Lawyers /> : <></>}

      {/* our achievments */}
      <AchievementsSection />

      {/* about */}
      <AboutSection />

      {/* form */}
      <FormSection />

      {/* footer */}

    </div>
  )
}
