import React, { useState } from 'react'
import { useTranslation } from 'react-i18next';
import Select from '../../../Components/Auth/Forms/Select';
import { useAuth } from '../../../Components/Auth/context';
import { useNavigate } from 'react-router-dom';

export default function HomeAuth() {
  const {setAuthType} = useAuth()
  const [auth,setAuth] = useState('client')
  const navigate = useNavigate()
  const { t } = useTranslation();
  const handleClick = () => {
    setAuthType(auth)
    navigate('/auth/login')
  }
  return (
    <div className='col-span-1 flex items-center justify-center'>
      <div className='flex flex-col gap-y-8 lg:p-8 lg:w-[480px]'>
          <h1 className='font-semibold text-[28px] text-white text-center leading-[48px] tracking-tight'>{t('home_auth_message')}</h1>
          <Select label={t('input_email')} name={'email'} onChange={(e)=>{
            setAuth(e.target.value)
          }} options={
            [
              {
                label: t('client'),
                value: 'client',
              },
              {
                label: t('lawyer'),
                value: 'lawyer',
              },
          ]}/>
          <div>
            <button onClick={()=> handleClick()} className='bg-main font-semibold text-second w-full px-2 py-3 rounded-xl'>{t('continue')}</button>
          </div>
      </div>
    </div>
  )
}
