import React from 'react';
import { Eye, EyeSlash, FilePrepend, Lock } from '../../../Icons/Icons';

const Input = React.forwardRef(({
    name,
    type,
    value,
    defaultValue,
    label,
    placeholder,
    onBlur,
    onChange,
    rightIcon,
    leftIcon,
    IsError,
    error,
    isFileLabel,
    onPaste
}, ref) => {
    const [visibility, setVisibility] = React.useState(false);
    const [fileName, setFileName] = React.useState(label);

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            setFileName(file.name);
            if (onChange) {
                onChange(event);
            }
        }
    };

    const renderInput = () => {
        switch (type) {
            case 'password':
                return (
                    <>
                        {label && <label htmlFor={name} className='text-[#FBF3E9]'>{label}</label>}
                        <div className='relative'>
                            <div className="relative mb-2">
                                <div className="absolute inset-y-0 start-0 flex items-center ps-3.5 pointer-events-none">
                                    <Lock />
                                </div>
                                <div className="absolute inset-y-0 end-0 flex items-center pe-3.5 cursor-pointer" onClick={() => setVisibility(!visibility)}>
                                    {!visibility ? <EyeSlash /> : <Eye />}
                                </div>
                                <input 
                                    onBlur={onBlur} 
                                    onChange={onChange} 
                                    value={value} 
                                    type={!visibility ? "password" : "text"} 
                                    id={name} 
                                    name={name} 
                                    className="input bg-gray-50 border-2 border-[#DCE4E8] placeholder:text-[#7B8389] text-black rounded-xl block w-full ps-12 p-4" 
                                    placeholder={placeholder} 
                                />
                            </div>
                            {IsError ? <div className='text-red-500'>{error}</div> : ''}
                        </div>
                    </>
                );
            case 'file':
                return (
                    <>
                        {isFileLabel && 
                            <div className='flex justify-between'>
                                <div className='text-[#FBF3E9] font-semibold'>رفع الهوية</div>
                                <div className='text-[#FBF3E9]'>يجب ارفاق الهوية امامية وخلفية*</div>
                            </div>
                        }
                        <div className='relative'>
                            <div className="relative mb-2">
                                <div className="absolute inset-y-0 start-0 flex items-center z-20 pointer-events-none">
                                    <FilePrepend />
                                </div>
                                <div className="absolute inset-0 border-2 border-[#DCE4E8] bg-white z-10 rounded-xl flex items-center pointer-events-none">
                                    <p className='text-center ps-14 font-semibold text-[#737373]'>{fileName}</p>
                                </div>
                                <div className="bg-main border-2 border-[#DCE4E8] absolute rounded-e-xl z-20 text-white inset-y-0 end-0 flex items-center px-7 py-3">
                                    رفع الملف
                                </div>
                                <input 
                                    onBlur={onBlur} 
                                    onChange={handleFileChange} 
                                    type="file" 
                                    id={name} 
                                    name={name} 
                                    className="input bg-gray-50 border-2 border-[#DCE4E8] placeholder:text-[#7B8389] text-black rounded-xl overflow-hidden block w-full ps-12 p-4" 
                                />
                            </div>
                            {IsError ? <div className='text-red-500'>{error}</div> : ''}
                        </div>
                    </>
                );
            case 'otp':
                return (
                    <>
                        {label && 
                            <label htmlFor={name} className='text-[#FBF3E9]'>{label}</label>
                        }
                        <div className='relative'>
                            <div className="relative mb-2">
                                {rightIcon && 
                                    <div className="absolute inset-y-0 start-0 flex items-center ps-3.5 pointer-events-none">
                                        {rightIcon}
                                    </div>
                                }
                                {leftIcon && 
                                    <div className="absolute inset-y-0 end-0 flex items-center pe-3.5 cursor-pointer">
                                        {leftIcon}
                                    </div>
                                }
                                <input
                                    onPaste={onPaste}
                                    ref={ref}
                                    onBlur={onBlur}
                                    defaultValue={defaultValue}
                                    onChange={(e) => {
                                        onChange(e);
                                        if (e.target.value.length === 1 && ref.current?.nextSibling) {
                                            ref.current.nextSibling?.focus();
                                        }
                                    }}
                                    value={value}
                                    type="text"
                                    inputMode="numeric"
                                    pattern="[0-9]*"
                                    maxLength="1"
                                    id={name}
                                    name={name}
                                    className="input bg-gray-50 border-2 border-[#DCE4E8] placeholder:text-[#7B8389] text-black rounded-xl block w-full p-2 text-3xl text-center"
                                    placeholder={placeholder}
                                    onKeyPress={(e) => {
                                        if (!/[0-9]/.test(e.key)) {
                                            e.preventDefault();
                                        }
                                    }}
                                />
                            </div>
                            {IsError ? <div className='text-red-500'>{error}</div> : ''}
                        </div>
                    </>
                );
            default:
                return (
                    <>
                        {label && 
                            <label htmlFor={name} className='text-[#FBF3E9]'>{label}</label>
                        }
                        <div className='relative'>
                            <div className="relative mb-2">
                                {rightIcon && 
                                    <div className="absolute inset-y-0 start-0 flex items-center ps-3.5 pointer-events-none">
                                        {rightIcon}
                                    </div>
                                }
                                {leftIcon && 
                                    <div className="absolute inset-y-0 end-0 flex items-center pe-3.5 cursor-pointer">
                                        {leftIcon}
                                    </div>
                                }
                                <input 
                                    onBlur={onBlur} 
                                    defaultValue={defaultValue} 
                                    onChange={onChange} 
                                    value={value} 
                                    type={type ? type : 'text'} 
                                    id={name} 
                                    name={name} 
                                    className="input bg-gray-50 border-2 border-[#DCE4E8] placeholder:text-[#7B8389] text-black rounded-xl block w-full ps-12 p-4" 
                                    placeholder={placeholder} 
                                />
                            </div>
                            {IsError ? <div className='text-red-500'>{error}</div> : ''}
                        </div>
                    </>
                );
        }
    };

    return (
        <div className='flex flex-col gap-2'>
            {renderInput()}
        </div>
    );
});

export default Input;
