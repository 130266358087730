export const TimeFormate = (time)=>{
    const date = new Date(time);
    const today = new Date();
    const yesterday = new Date();
    yesterday.setDate(today.getDate() - 1);
    
    const isToday = date.toDateString() === today.toDateString();
    const isYesterday = date.toDateString() === yesterday.toDateString();
    
    let dayText;
    if (isToday) {
        dayText = "اليوم";
    } else if (isYesterday) {
        dayText = "الأمس";
    } else {
        dayText = date.toLocaleDateString('ar-EG', { weekday: 'long' });
    }

    const options = {
      day: 'numeric',
      month: 'long',
      year: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      hour12: true
    };
    
    // تنسيق التاريخ بالعربية
    const formattedDate = date.toLocaleDateString('ar-EG', options);
    return {formattedDate , dayText}
}