import ArrowCircleLeftOutlinedIcon from '@mui/icons-material/ArrowCircleLeftOutlined';
import ArrowCircleRightOutlinedIcon from '@mui/icons-material/ArrowCircleRightOutlined';

const PrevArrow = (props) => {
    const { className,  onClick } = props;
    return (
        <div onClick={onClick} className={`arrow ${className}`} >
            <ArrowCircleLeftOutlinedIcon sx={{ color: "white", fontSize: "30px" }} />
        </div>
    )
}

const NextArrow = (props) => {
    const { className, onClick } = props;
    return (
        <div onClick={onClick} className={`arrow ${className}`} >
            <ArrowCircleRightOutlinedIcon sx={{ color: "white", fontSize: "30px" }} />
        </div>
    )
}

export { PrevArrow, NextArrow }