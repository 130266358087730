import {
    useMutation,
    useQueryClient,
  } from '@tanstack/react-query';  
  export const useCustomMutation = ({
    mutationFn,
    queryToInvalidate,
    onSuccess,
    onError,
  }) => {
    const queryClient = useQueryClient();
  
    const newSupplierMutation = useMutation({
      mutationFn: mutationFn,
      onSuccess(data) {
        queryToInvalidate && queryClient.invalidateQueries(queryToInvalidate);
        onSuccess?.(data);
      },
      onError(error) {
        onError?.(error);
      },
    });
  
    return newSupplierMutation;
  };
  