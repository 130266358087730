import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSpecialties } from '../../../data/vistor';
import InputField from '../../Shared/InputField';

const type = [
    { label: 'incoming', value: 'incoming' },
    { label: 'call', value: 'call' },
];

function  RequestConsultationForm({ Formik, setStep , disabled }) {
    const { t } = useTranslation();
    const {data} = useSpecialties()
    return (
        <div className="flex flex-col gap-12">
            <div>
                <div className='bg-second-card rounded-xl py-8 md:px-11 px-5 text-white'>
                    <div className='grid grid-cols-1 gap-5'>
                        <InputField
                            onBlur={Formik.handleBlur}
                            onChange={Formik.handleChange}
                            value={Formik.values.specialty}
                            IsError={Formik.errors.specialty && Formik.touched.specialty} 
                            error={Formik.errors.specialty}  
                            name='specialty'
                            as='select'
                            label={t('input_consultation_type')}
                            children={<>
                            <option value=''>{t('input_consultation_type')}</option>
                            {data.map((item, index) => (
                                <option key={index} value={item.key}>{item.value}</option>
                            ))}
                            </>}
                        />
                        <InputField
                            onBlur={Formik.handleBlur}
                            onChange={Formik.handleChange}
                            value={Formik.values.type}
                            IsError={Formik.errors.type && Formik.touched.type} 
                            error={Formik.errors.type}  
                            name='type'
                            as='select'
                            label={t('input_consultation_select')}
                            children={<>
                            <option value=''>{t('input_consultation_select')}</option>
                            {type.map((item, index) => (
                                <option key={index} value={item.value}>{t(item.label)}</option>
                            ))}
                            </>}
                        />
                    </div>
                </div>

                <div className='pt-10 flex justify-center'>
                    <button 
                        disabled={disabled}
                        onClick={()=> setStep(2)} 
                        className={`btn bg-main text-dark px-7 py-4 xl:w-[30%] w-[60%] rounded-xl`}
                    >
                        <p className='font-medium'>{t('continue')}</p>
                    </button>
                </div>
            </div>
        </div>
    );
}

export default RequestConsultationForm;
