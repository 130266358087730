import React from 'react'
import { useCustomMutation } from '../../../hooks/use-custome-mutation';
import client from '../../../client';
import { API_ENDPOINTS } from '../../../client/endpoints';
import SwitchStatus from '../../Shared/SwitchStatus';
import DayTime from './DayTime';

export default function WorkTime({item ,day }) {
    const [status , setStatus] = React.useState(false)
    React.useEffect(() => {
      if (item) {
        setStatus(item.active)
      }
  }, [item]);  
  const updateMutation = useCustomMutation({
    mutationFn: client.WorkTime.update_work_time,
    queryToInvalidate: {queryKey: [API_ENDPOINTS.WORK_TIME],},
  });
  const toggleStatus = () => {
    setStatus(!status)
    updateMutation.mutate({id:item._id, active:!status , periods:item.periods})
  } 
  



  return (
    <>
    {item.day === day &&
    <div className='flex flex-col gap-4'>
      <SwitchStatus
          checked={status}
          onChange={toggleStatus}
          />
    {item.periods.map((period , index)=>
     <DayTime key={index} item={period} workTimeId={item._id}/>)}
     </div>}
     
    </>
    
  )
}
