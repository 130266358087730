import React from 'react'
import { useTranslation } from 'react-i18next';
import EarningDetailsItem from './EarningDetailsItem';
import { useTransactions } from '../../../../data/vistor';

function EarningDetails() {
  const {data} = useTransactions()
    const { t } = useTranslation();
  return (
    <div className="flex flex-col gap-12">
    <div className='bg-second-card border-white border-2 rounded-xl py-8 md:px-11 px-5 text-white'>
        <div className='flex flex-col gap-5'>
            <h2 className='font-semibold leading-5'>{t('earnings_details')}</h2>
            <div className='flex flex-col gap-[10px]'>
            {data.map(item=>
            <EarningDetailsItem key={item._id} item={item} />
            )}
            {/* <EarningDetailsItem type={'إستشارة'} value='20,10'earning_state={'مكتملة'}/> */}
            </div>
        </div>
    </div>
</div>
  )
}

export default EarningDetails