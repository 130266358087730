import React from 'react'
import { Rating } from '@mui/material'
import { Link } from 'react-router-dom'

function LawyerCard({ lawyer}) {
  return (
      <div className='h-full border-4 bg-second-card border-main rounded-xl p-5 text-right max-w-[22rem] mx-auto'>
        <div className='flex flex-col gap-3'>
          <img src={lawyer.profileImage} alt="" className='w-full object-center h-[300px] object-cover rounded-xl' />
          <h4 className='text-main text-xl'>{lawyer.mainSpecialty.value}</h4>
          <h4 className='text-white text-xl'>{lawyer.name}</h4>
          <Rating name="read-only" value={lawyer.ratingsCount} readOnly className='ml-auto' />
          <Link to={`/lawyer/${lawyer._id}`} className="rounded py-3 font-semibold px-10 bg-main text-dark">عرض التفاصيل</Link>
        </div>

      </div>
  )
}

export default LawyerCard