import React from 'react';
import { useTranslation } from "react-i18next";
import { useFormik } from "formik";
import * as Yup from 'yup';
import client from "../../../client";
import { API_ENDPOINTS } from "../../../client/endpoints";
import { useCustomMutation } from "../../../hooks/use-custome-mutation";
import { CircularProgress } from "@mui/material";
// import cookies from 'js-cookie';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import moment from 'moment';

export default function AddWorkTime({ day }) {
  // const locale = cookies.get('locale');
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState('');
  const { t } = useTranslation();

  const AddWorkTimeMutation = useCustomMutation({
    mutationFn: client.WorkTime.add_work_time,
    queryToInvalidate: { queryKey: [API_ENDPOINTS.WORK_TIME] },
    onSuccess: (data) => {
      if (data.status) {
        setLoading(false);
        AddWorkTime.resetForm();
      }
    },
    onError: (error) => {
      setLoading(false);
      setError(error.message);
      setTimeout(() => {
        setError('');
      }, 5000);
    },
  });

  const AddWorkTime = useFormik({
    initialValues: {
      fromTime: '',
      toTime: '',
      day: day,
      active: true,
    },
    validationSchema: Yup.object({
      fromTime: Yup.string().required(t('from_time_required')),
      toTime: Yup.string()
        .required(t('to_time_required'))
        .test('is-greater', t('to_time_must_be_later'), function (value) {
          const { fromTime } = this.parent;
          return !fromTime || !value || fromTime < value;
        }),
    }),
    onSubmit: (values) => {
      const transformedValues = {
        day: day,
        active: values.active,
        periods: [
          {
            fromTime: moment(values.fromTime, 'HH:mm').format('hh:mmA'),
            toTime: moment(values.toTime, 'HH:mm').format('hh:mmA')
          }
        ]
      }
      setLoading(true);
      AddWorkTimeMutation.mutate(transformedValues);
    }
  });

  return (
    <div className='flex flex-row items-center gap-3'>
      <label htmlFor='fromTime'>{t('from')}</label>
      <input
        value={AddWorkTime.values.fromTime}
        onBlur={AddWorkTime.handleBlur}
        onChange={AddWorkTime.handleChange}
        name='fromTime'
        id='fromTime'
        className='bg-day text-white p-1 rounded-md'
        type="time"
      />
      {AddWorkTime.touched.fromTime && AddWorkTime.errors.fromTime && (
        <div className="text-red-500 text-xs">{AddWorkTime.errors.fromTime}</div>
      )}
      <label>{t('to')}</label>
      <input
        value={AddWorkTime.values.toTime}
        onBlur={AddWorkTime.handleBlur}
        onChange={AddWorkTime.handleChange}
        name='toTime'
        className='bg-day text-white p-1 rounded-md'
        type="time"
      />
      {AddWorkTime.touched.toTime && AddWorkTime.errors.toTime && (
        <div className="text-red-500 text-xs">{AddWorkTime.errors.toTime}</div>
      )}
      <button
        type='button'
        className='flex items-center gap-1'
        disabled={loading}
        onClick={AddWorkTime.handleSubmit}
      >
        {loading ? (
          <CircularProgress size={16} />
        ) : (
          <>
            <AddCircleOutlineIcon sx={{ fontSize: '20px' }} className='text-main' />
            <p className='text-main font-normal text-[20px] leading-4'>{t('add')}</p>
          </>
        )}
      </button>
      {error && <div className="text-red-500 text-xs mt-2">{error}</div>}
    </div>
  );
}
