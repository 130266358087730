import React from 'react'
import TimeWork from './TimeWork'
import InfoCard from './InfoCard'
import { useTranslation } from 'react-i18next'

export default function LawyerCard({Formik,setStep , user}) {
    const {t} = useTranslation()
  return (
    <div className='bg-second-card p-6 rounded-xl flex flex-col gap-6'>
        <InfoCard user={user}/>
        {user?.specialties?.length>0 && 
        <div className='flex flex-col gap-2'>
            <h3>{t('specialties')}</h3>
            <div className='flex gap-7'>
                {user?.specialties.map((specialty , index)=>
                <div key={index} className='p-[10px] rounded-lg flex items-center justify-center border-main text-sm font-bold'>
                    {specialty}
                </div>
                )}
            </div>
        </div>
        }
        {user?.workTimes?.length>0 && 
        <div className='flex flex-col gap-3'>
            <h3>{t('time_work')}</h3>
            <div className='flex flex-col gap-5'>
                {user?.workTimes.map(item=>
                <TimeWork time={item} key={item._id}/>
                )}
            </div>
        </div>
        }
        <button onClick={()=> {
            setStep(3)
            Formik.setFieldValue('lawyer', user._id);
        }} className='bg-main rounded-xl py-3 font-medium text-black'>{t('request_consultation')}
        </button>
    </div>
)
}
