import React from 'react'

function StaticInputField({label, value}) {
  return (
    <div>
        <div className='flex flex-col gap-2 styled-select'>
        <p className='leading-5 text-sm'>{label}</p>
        <input className={`text-[#92A0AF] bg-input-second rounded-2xl py-4 px-3 flex flex-row-reverse focus:outline-none`}  value={value} readOnly/>
</div>
    </div>
  )
}

export default StaticInputField