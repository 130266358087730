import React from 'react'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { useCustomMutation } from '../../../../hooks/use-custome-mutation';
import client from '../../../../client';
import { CircularProgress } from '@mui/material';
import InputField from '../../../Shared/InputField';
import { useTranslation } from 'react-i18next';
function TransactionAmountForm({ limit = 0, setVisible, visible = false }) {
    const [loading, setLoading] = React.useState(false);
    const [error, setError] = React.useState('');
    const [success, setSuccess] = React.useState('');
    const [btnClicked, setBtnClicked] = React.useState('');

    const { t } = useTranslation();


    const validationSchema = Yup.object({
        amount: Yup.number().max(limit, 'ليس لديك رصيد كافي').required('المبلغ مطلوب'),
    });
    const TransactionMutation = useCustomMutation({
        mutationFn: client.Transactions.post_transitions,
        onSuccess: (data) => {
            setLoading(false)
            setSuccess('تم التحديث بنجاح')
            setTimeout(() => {
                setSuccess('')
            }, 5000)
        },
        onError: (error) => {
            setLoading(false)
            setError('حدث خطأ ما, الرجاء المحاولة لاحقاً')
            setTimeout(() => {
                setError('')
            }, 5000)
        },
    });

    let TransactionFormik = useFormik({
        enableReinitialize: true,
        initialValues: {
            amount: 0,
        },
        validationSchema,
        onSubmit: (values) => {
            if (btnClicked === 'submit') {
                setLoading(true);
                const transactionAmount = {
                    amount: values.amount
                }
                TransactionMutation.mutate(transactionAmount);
            }
            else{
                setVisible(false)
            }
        }
    })
    return (
        <div className={`${visible ? 'block' : 'hidden'}`}>
            <form onSubmit={TransactionFormik.handleSubmit}>
                <div className="flex flex-col gap-12">
                    <div className='bg-second-card border-white border-2 rounded-xl py-8 md:px-11 px-5 text-white'>
                        <div className='flex flex-col gap-5'>
                            <h2 className='text-center font-semibold leading-5'>{t('input_withdraw_balance')}</h2>
                            <div className='flex flex-col gap-[10px]'>
                                <InputField
                                    name={'amount'}
                                    type={'number'}
                                    placeholder={`الحد الاقصى ${limit}`}
                                    onBlur={TransactionFormik.handleBlur}
                                    onChange={TransactionFormik.handleChange}
                                    value={TransactionFormik.values.amount}
                                    IsError={TransactionFormik.errors.amount && TransactionFormik.touched.amount}
                                    error={TransactionFormik.errors.amount} />
                            </div>
                        </div>
                        {error && <div className='text-red-600 text-center'>{error}</div>}
                        {success && <div className='text-green-600 text-center'>{success}</div>}
                        <div className='pt-10 flex justify-center gap-3'>
                            <button className={`btn bg-main text-dark px-7 py-4 xl:w-[30%] w-[60%] rounded-xl`}
                                type='submit'
                                onMouseOver={(event) => {
                                    setBtnClicked('submit');
                                }}>
                                {loading ? <CircularProgress style={{
                                    width: '20px',
                                    height: '20px',
                                    color: '#fff',
                                }} /> : <p className='font-bold'> {t('withdraw_balance')} </p>}
                            </button>
                            <button className={`btn bg-main text-dark px-7 py-4 xl:w-[30%] w-[60%] rounded-xl`}
                                onMouseOver={(event) => {
                                    setBtnClicked('close');
                                }}>
                                <p className='font-bold'> {t('cancel')} </p>
                            </button>
                        </div>
                    </div>
                </div>

            </form>

        </div>
    )
}

export default TransactionAmountForm