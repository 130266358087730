import React from 'react'
import { useTranslation } from 'react-i18next';
import NotificationItem from '../../../Components/Lawyer/Notifications/NotificationItem/NotificationItem';
import { useNotifications } from '../../../data/vistor';
import LoadingData from '../../../Components/Shared/LoadingData';
import NotFoundData from '../../../Components/Shared/NotFoundData';

function Notifications() {
    const [pagination, setPagination] = React.useState({
        pageIndex: 0,
        pageSize: 50,
      });
      const [userData, setUserData] = React.useState([]);    
    const { t } = useTranslation();
    const {data: fetchedUsers = {}, isPending: isLoadingUsers , isError} = useNotifications({
        page: pagination.pageIndex + 1,
        limit: pagination.pageSize,
      })

      const [isInitialLoading, setIsInitialLoading] = React.useState(true);
    
    
      React.useEffect(() => {
        if (fetchedUsers.data) {
          setIsInitialLoading(false);
          setUserData(() => [...fetchedUsers.data]);
        }
        if(isError){
          setIsInitialLoading(false);
        }
      }, [fetchedUsers.data,isError]);
    
      React.useEffect(() => {
        const handleScroll = () => {
          const bottom = Math.ceil(window.innerHeight + window.scrollY) >= document.documentElement.scrollHeight;
          if (bottom && !isLoadingUsers && pagination.pageSize < fetchedUsers.pagination?.resultCount) {
            setPagination((prev) => ({
              ...prev,
              pageSize: prev.pageSize + 20,
            }));
          }
        };
    
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
      }, [isLoadingUsers, fetchedUsers, pagination.pageSize]);
        
    
    return (
        <div className='bg-second h-full py-16'>
            <div className='container mx-auto'>
                <h1 className='text-white text-xl font-bold leading-5'>{t('notifications')}</h1>
                <div className='flex flex-col gap-8 py-12'>
                {isInitialLoading ? <LoadingData/>:
                    userData.length>0?userData.map((item, index) => {
                        return (
                            <NotificationItem key={index} item={item} />
                        )
                    }):<NotFoundData Message={t('Not_found_Notifications')}/>}
                    {isLoadingUsers && !isInitialLoading &&
                    <LoadingData/>
                    }
                </div>
            </div>
        </div>
    )
}

export default Notifications