import React from 'react'

function AboutWebSiteCard({title, description}) {
  return (
    <div className='text-right relative px-5 flex border-main-color'>
        <div className='w-2 rounded-lg bg-main '></div>
        <div>
          <h4 className='text-main text-xl font-semibold mb-5'>{title}</h4>
          <p className='text-white font-medium leading-7 ps-5'>{description}</p>
        </div>
    </div>
  )
}

export default AboutWebSiteCard