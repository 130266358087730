import React from 'react'
import {FacebookIcon, FacebookShareButton , LinkedinIcon, LinkedinShareButton, TwitterShareButton, WhatsappIcon, WhatsappShareButton, XIcon} from "react-share";
import { Modal } from "@mui/material";

export default function ShareLink({openModel , setOpenModel  , shareUrl

 }) {
  return (
    <Modal open={openModel} onClose={()=> setOpenModel(false)}>
    <div className="bg-second-card absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 px-16 py-10 rounded-3xl shadow-2xl max-h-[600px] overflow-auto">
      <div className="grid grid-cols-2 gap-2">
        <FacebookShareButton
          url={shareUrl}
        >
          <FacebookIcon size={32} round />
        </FacebookShareButton>
        <WhatsappShareButton
          url={shareUrl}
          title={'Share Profile'}
        >
          <WhatsappIcon size={32} round />
        </WhatsappShareButton>
        <LinkedinShareButton
    url={shareUrl}
  >
    <LinkedinIcon size={32} round />
  </LinkedinShareButton>
  <TwitterShareButton
    url={shareUrl}
    title={'Share Profile'}
    className="Demo__some-network__share-button"
  >
    <XIcon size={32} round />
  </TwitterShareButton>

      </div>
  </div>
</Modal>
  )
}
