
import React from 'react'
import Form from './Form';
import { useTranslation } from 'react-i18next';

import FormImage from '../../../assets/images/form.jpg'
import ArrowCircleUpIcon from '@mui/icons-material/ArrowCircleUp';

function FormSection() {
    const { t } = useTranslation();
    return (
        <div id='contact' className='bg-second py-20'>
            <div className='container mx-auto relative'>
                <div className='absolute -bottom-16 right-5'>
                    <button onClick={() => { window.scrollTo({ top: 0, behavior: "smooth" }) }}>
                        <ArrowCircleUpIcon sx={{ fontSize: '50px' }} className='text-main' />
                    </button>
                </div>
                <h3 className='text-4xl font-semibold text-white text-center mb-20'>{t('how_to_help')}</h3>
                <div className="grid grid-cols-12 gap-2 md:mx-0 mx-3">
                    <div className="lg:col-span-6 col-span-12">
                        <div className='bg-second-card  !border-b-transparent lg:border-b-main border-main border-4 rounded-xl lg:border-l-0 lg:rounded-tl-none lg:rounded-bl-none'>
                            <h4 className='text-white text-center text-3xl font-semibold p-5'>{t('contact_uss')}</h4>
                            <Form />
                            {/* <h4 className='text-white text-center '>{t('or_contact_us_via')}</h4>
                            <div className='flex justify-center items-center p-5'>
                                <button onClick={() => { window.open('https://wa.me/966500000000') }}>
                                    <WhatsAppIcon sx={{ fontSize: '50px' }} className='text-main' />
                                </button>
                            </div> */}
                        </div>
                    </div>
                    <div className="lg:col-span-6 col-span-12 block">
                        <img className='w-full h-full rounded-lg rounded-tr-none rounded-br-none' src={FormImage} alt="" />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default FormSection