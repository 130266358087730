import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link, Outlet, useLocation } from 'react-router-dom'

export default function TabsLayout() {
    const{t} = useTranslation()
    const location = useLocation()
    const path = location.pathname.split('/')[1]
    const isRequestConsultation = ['request-consultation'].includes(path)
    const isAppointmentBooking = ['take-appointment'].includes(path)
  return (
    <div className="pt-10 pb-12 container">
        <div className='py-1'>
            <div className='flex gap-4 !border-b-4 !border-x-0 !border-t-0 border-main'>
                <Link to='request-consultation' className={`px-4 py-5 text-center rounded-t-xl w-full ${isRequestConsultation && 'bg-second-card' }`}>
                    <span className='text-white font-medium'>{t('request_consultation')}</span>
                </Link >
                <Link to='take-appointment' className={`px-4 py-5 text-center rounded-t-xl w-full ${isAppointmentBooking && 'bg-second-card' }`}>
                    <span className='text-white font-medium'>{t('appointment_booking')}</span>
                </Link >
            </div>
        </div>
        <div className='pt-16 relative'>
            <Outlet/>
        </div>
    </div>
  )
}
