import React from 'react'
import PersonalImage from '../../../Components/Shared/PersonalImage';
import PersonalInfoFrom from '../../../Components/PersonalInfo/PersonalInfoFrom';
import MainInformation from '../../../Components/Client/Profile/MainInformation';
import CallInformation from '../../../Components/Client/Profile/CallInformation';
import { useMe } from '../../../data/user';

function Profile() {
    const {me} = useMe()


    return (
            <div className="pt-10 pb-12 container mx-auto">
                {/* image */}
                <div className='flex flex-col gap-9'>
                    <PersonalImage/>
                {me?.type==='client'?
                <>
                    <MainInformation />
                    <CallInformation />
                </>:
                    <PersonalInfoFrom/>
                }
                </div>
                {/* main information */}
            </div>
    )
}

export default Profile;
