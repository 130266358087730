import React from 'react'
import Slider from 'react-slick'
import AboutWebSiteCard from '../AboutWebSiteCard'
import { useTranslation } from 'react-i18next'
import { useAboutApp } from '../../../data/vistor'
const min = (a, b) => a < b ? a : b
function getSettings(minCount, isInfinite = true, isDots = false) {
    return {
        dots: isDots,
        infinite: isInfinite,
        autoplay: true,
        speed: 500,
        slidesToShow: min(minCount, 4),
        slidesToScroll: 3,
        initialSlide: 0,
        nextArrow: <></>,
        prevArrow: <></>,
        responsive: [
            {
                breakpoint: 1280,
                settings: {
                    slidesToShow: min(minCount, 3),
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: min(minCount, 2),
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: min(minCount, 1),
                    slidesToScroll: 1,
                }
            },
        ]
    }
}

const aboutWebSiteList = [
    {
        title: 'هذا النص هو مثال  ؟',
        description: 'مرحبًا بكم في شركة مُحامٍ للمحاماة والاستشارات القانونية، شريككم الموثوق في عالم القانون بدولة الكويت. نحن فخورون بكوننا من الشركات الرائدة في مجال المحاماة، ونتشرف باعتمادنا من قبل الهيئة الكويتية للمحامين.'
    },
    {
        title: 'هذا النص هو مثال  ؟',
        description: 'مرحبًا بكم في شركة مُحامٍ للمحاماة والاستشارات القانونية، شريككم الموثوق في عالم القانون بدولة الكويت. نحن فخورون بكوننا من الشركات الرائدة في مجال المحاماة، ونتشرف باعتمادنا من قبل الهيئة الكويتية للمحامين.'
    },
    {
        title: 'هذا النص هو مثال  ؟',
        description: 'مرحبًا بكم في شركة مُحامٍ للمحاماة والاستشارات القانونية، شريككم الموثوق في عالم القانون بدولة الكويت. نحن فخورون بكوننا من الشركات الرائدة في مجال المحاماة، ونتشرف باعتمادنا من قبل الهيئة الكويتية للمحامين.'
    },
    {
        title: 'هذا النص هو مثال  ؟',
        description: 'مرحبًا بكم في شركة مُحامٍ للمحاماة والاستشارات القانونية، شريككم الموثوق في عالم القانون بدولة الكويت. نحن فخورون بكوننا من الشركات الرائدة في مجال المحاماة، ونتشرف باعتمادنا من قبل الهيئة الكويتية للمحامين.'
    },
]
function AboutSection() {
    const {data } = useAboutApp()
    const { t } = useTranslation();
    return (
        <div id='about' className='bg-dark py-20 '>
            <div className='container mx-auto'>

                <h3 className='text-4xl font-semibold text-white md:text-center text-right mb-20 md:mr-0 mr-3'>{t('about_website')}</h3>
                <Slider {...getSettings((aboutWebSiteList.length))}>
                    {data.map((about) => (
                        <AboutWebSiteCard key={about._id} title={about.title} description={about.description} />
                    ))}
                </Slider>
            </div>
        </div>
    )
}

export default AboutSection