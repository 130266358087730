import { Navigate, Outlet } from 'react-router-dom';
import { useMe } from '../data/user';


const PrivateRoute = () => {
  const { isAuthorized } = useMe();
  return isAuthorized ? <Outlet/> : <Navigate to={'/auth/login'} />;
};

export default PrivateRoute;
