import React from 'react'
import { useTranslation } from 'react-i18next';
import { TimeFormate } from '../../data/TimeFormate';
import { Link } from 'react-router-dom';
import { useMe } from '../../data/user';
import { useCustomMutation } from '../../hooks/use-custome-mutation';
import client from '../../client';
import { API_ENDPOINTS } from '../../client/endpoints';
import { CircularProgress, Modal } from '@mui/material';

function RequestItem({ item, buttonText , hasButton , buttonMain }) {
    const { t } = useTranslation();
    const [openModel, setOpenModel] = React.useState(false)
    const formate = TimeFormate(item.reservationDate)    
    const {me} = useMe()
    const update = useCustomMutation({
        mutationFn:client.Consultants.update,
        queryToInvalidate: {
            queryKey: [API_ENDPOINTS.GET_CONSULTATION({id:item._id})],
        },
        onSuccess: (data) => {
        },
        onError : (err) => {
            if(err.code === 403){
                setOpenModel(true)
            }
        }
    })
    
  return (
    // <div className="bg-second-card pb-8 pt-10 pl-6 pr-16 rounded-md relative">
    <div className="bg-second-card py-2 md:pl-6 md:pr-16 pl-3 pr-14 rounded-md relative">
    {/* image */}
        <Link to={`/my-request/${item._id}`} className='w-10 h-10 rounded-full absolute overflow-hidden top-2 right-2'>
            <img className='w-full h-full' src={item?.client?.profileImage?item?.client?.profileImage : item?.lawyer?.profileImage} alt={item?.client?.name || item?.lawyer?.name} />
        </Link>

        <div className='flex justify-between items-center'>
            <div className='flex flex-col'>
                <h3 className='text-white text-[10px] font-bold leading-5'>{item.type==='reservation'?t('reservation'):t('consultant')}</h3>
                <h3 className='text-main text-sm font-semibold leading-6'>{item?.client?.name?item?.client?.name: item?.lawyer?.name}</h3>
                <p className=' text-white text-[8px] leading-5 mt-2'>{`${formate.dayText} ${formate.formattedDate}`}</p>

            </div>
            <Link to={`/my-request/${item._id}`} className='  absolute inset-0'>
            </Link>
            {hasButton && (
                (me?.type==='lawyer' && item.status ==='active')?
            
            <button onClick={()=> update.mutate({
                id:item._id,
                status:'finished'
            })} className={`bg-main relative z-20 border-main lg:py-4 lg:px-10 py-2 px-2 rounded-md`}>
                {update.isPending?<CircularProgress style={{
                                    width: '20px',
                                    height: '20px',
                                    color: '#fff',
                                }} />:
                <p className={`text-dark  text-xs font-semibold leading-5`}>{t('request_done')}</p>
                                }
            </button>:
            <Link to={`/my-request/${item._id}`} className={`${buttonMain ? "bg-main":"bg-transparent"} border-main lg:py-4 lg:px-10 py-2 px-2 rounded-md`}>
                <p className={`${buttonMain ? "text-dark":"text-main"}  text-xs font-semibold leading-5`}>{buttonText}</p>
            </Link>
            )}
        </div>
        <Modal open={openModel} onClose={()=> setOpenModel(false)}>
                    <div className="bg-second-card absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 px-16 md:px-32 py-10 rounded-3xl shadow-2xl max-h-[600px] overflow-auto">
                        <div className='flex flex-col gap-6 items-center'>
                            <h2 className='text-main text-3xl font-bold'>اشترك في خطة اولاً</h2>
                            <Link to='/subscriptionplans' className='bg-main py-2 px-5 rounded-md font-medium text-lg'>للاشتراك</Link>

                        </div>
                    </div>
                </Modal>

    </div>
  )
}

export default RequestItem