import React from 'react'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { useNavigate, useSearchParams } from 'react-router-dom';
import { API_ENDPOINTS } from '../../../client/endpoints';
import { useAuth } from '../../../Components/Auth/context';
import client from '../../../client';
import { CircularProgress } from '@mui/material';
import { useCustomMutation } from '../../../hooks/use-custome-mutation';
import { useTranslation } from 'react-i18next';
import Input from '../../../Components/Auth/Forms/Input';

export default function ResetPassword() {
  const { t } = useTranslation();
  const [error, setError] = React.useState('')
  let navigate = useNavigate()
  const { authorize } = useAuth();
  const [searchParams] = useSearchParams();
  const user = searchParams.get('user');

  let validationSchema = Yup.object({
    password: Yup.string()
      .required('كلمة المرور مطلوبة')
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
        'يجب أن تكون كلمة المرور مكونة من 8 أحرف على الأقل وتشمل حرفًا كبيرًا وحرفًا صغيرًا ورقمًا وحرفًا خاصًا'
      ),
    confirmPassword: Yup.string()
      .required('تأكيد كلمة المرور مطلوب')
      .oneOf([Yup.ref('password'), null], 'يجب أن تتطابق كلمات المرور'),
    })
  const resetPasswordMutation = useCustomMutation({
    mutationFn: client.auth.reset_password,
    queryToInvalidate: {
      queryKey: [API_ENDPOINTS.GET_PROFILE],
    },
    onSuccess: (data) => {
      if (data.status) {
        authorize(data.data);
        navigate('/');
      }
    },
    onError: (data) => {
      setError(data.message)
      setTimeout(() => {
        setError('')
      }, 5000)
    },
  });

  let Formik = useFormik({
    initialValues:{
      user:user || '',
      password:'',
      confirmPassword:''
    },
    validationSchema,
    onSubmit:(values)=>{
      resetPasswordMutation.mutate(values)
    }
  })

  return (
    <div className='flex flex-col'>
        <form onSubmit={Formik.handleSubmit} className='flex flex-col gap-y-8 lg:p-8 lg:w-[480px]'>
            <h1 className='font-semibold text-[28px] text-white text-center leading-[48px] tracking-tight'>{t('reset_auth_message')}</h1>
                <Input 
                    onBlur={Formik.handleBlur} 
                    onChange={Formik.handleChange}
                    value={Formik.values.password}
                    name={'password'}
                    type={'password'}
                    
                    label={t('input_password')}
                    placeholder={t('placeholder_password')}
                    IsError={Formik.errors.password && Formik.touched.password} 
                    error={Formik.errors.password}  
                />
                <Input 
                    onBlur={Formik.handleBlur} 
                    onChange={Formik.handleChange}
                    value={Formik.values.confirmPassword}
                    name={'confirmPassword'}
                    type={'password'}
                    label={t('input_confirm_password')}
                    placeholder={t('placeholder_password')}
                    IsError={Formik.errors.confirmPassword && Formik.touched.confirmPassword} 
                    error={Formik.errors.confirmPassword}
                />
            <div>
                {error && <div className='text-red-500 text-center'>{error}</div>}
                <button type='submit' disabled={!(Formik.isValid && Formik.dirty ) || resetPasswordMutation.isPending} className='bg-main font-semibold text-second w-full px-2 py-3 rounded-xl'>
                {resetPasswordMutation.isPending?<CircularProgress style={{
                    width: '20px',
                    height: '20px',
                    color: '#fff',
                }} />:t('confirm')}</button>
            </div>
        </form>
    </div>

  )
}
