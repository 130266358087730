import React from 'react';
import { ArrowDown } from '../../Icons/Icons';
import * as ReactDOMServer from "react-dom/server";

function InputField({ name, label, value, defaultValue, className, onBlur, onChange, IsError, error, type = 'text', as = 'input', cols = 1, rows = 1, placeholder = '', disabled = false, children = null }) {
    const svgString = encodeURIComponent(
        ReactDOMServer.renderToStaticMarkup(<ArrowDown />)
    );

    // Custom onChange handler for multiple select
    const handleMultipleChange = (e) => {
        const selectedOptions = Array.from(e.target.selectedOptions).map(option => option.value);
        onChange({
            target: {
                name: e.target.name,
                value: selectedOptions
            }
        });
    };

    return (
        <div className='flex flex-col gap-2 styled-select'>
            <label htmlFor={name} className='leading-5 text-sm'>{label}</label>

            {as === 'input' &&
                <input
                    value={value}
                    onBlur={onBlur}
                    onChange={onChange}
                    type={type}
                    defaultValue={defaultValue}
                    disabled={disabled}
                    id={name}
                    name={name}
                    placeholder={placeholder}
                    autoComplete="off"
                    className="text-[#92A0AF] bg-input-second rounded-2xl ps-6 py-4 px-3 flex flex-row-reverse focus:outline-none"
                />}

            {as === 'textarea' &&
                <textarea
                    value={value}
                    onBlur={onBlur}
                    onChange={onChange}
                    disabled={disabled}
                    id={name}
                    name={name}
                    placeholder={placeholder}
                    cols={cols}
                    rows={rows}
                    className="text-[#92A0AF] bg-input-second rounded-2xl ps-6 py-4 px-3 flex flex-row-reverse focus:outline-none"
                />
            }

            {as === 'select' &&
                <select
                    value={value}
                    onBlur={onBlur}
                    onChange={onChange}
                    disabled={disabled}
                    id={name}
                    name={name}
                    className={`text-[#92A0AF] bg-input-second rounded-2xl ps-6 py-4 px-3 flex flex-row-reverse focus:outline-none appearance-none bg-no-repeat bg-[image:var(--image-url)] ${className}`}
                    style={{ '--image-url': `url("data:image/svg+xml,${svgString}")` }}
                >
                    {children}
                </select>}

            {as === 'multiple' &&
                <select
                    multiple
                    value={value}
                    onBlur={onBlur}
                    onChange={handleMultipleChange}
                    disabled={disabled}
                    id={name}
                    name={name}
                    className={`text-[#92A0AF] bg-input-second rounded-2xl ps-6 py-4 px-3 flex flex-row-reverse focus:outline-none appearance-none bg-no-repeat bg-[image:var(--image-url)] ${className}`}
                    style={{ '--image-url': `url("data:image/svg+xml,${svgString}")` }}
                >
                    {children}
                </select>}

            {IsError ? <div className='text-red-500'>{error}</div> : ''}
        </div>
    );
}

export default InputField;
