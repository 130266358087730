export class APIError{

  constructor(data) {
      this.errors = data.data ?? [];
      this.message = data.data.message ||'';
      this.code = data.data.code || '';
      // switch (data?.status) {
      //   case 401:
      //     return this.message = 'Unauthorized';
      //   case 403:
      //     return this.message = 'Forbidden';
      //   case 404:
      //     return this.message = 'Not Found';
      //   case 405:
      //     return this.message = 'Method Not Allowed';
      //   case 422:
      //     return this.message = 'Validation Failed';
      //   case 423:
      //     return this.message = 'Token Expired';
      //   case 429:
      //     return this.message = 'Too Many Requests';
      //   case 500:
      //     return this.message = 'Internal Server Error';
      //   default:
      //     return this.message = 'Internal Server Error';
      // }
  }
}