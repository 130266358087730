import React from 'react'
import FindLawyer from '../FindLawyer/FindLawyer';
import TakeAppointmentForm from '../../../Components/Client/TakeAppointment/TakeAppointmentForm';
import * as Yup from 'yup'
import { useCustomMutation } from '../../../hooks/use-custome-mutation';
import client from '../../../client';
import { useFormik } from 'formik';
import { API_ENDPOINTS } from '../../../client/endpoints';
import SendConsultant from '../SendConsultant/SendConsultant';
import { ArrowForward } from '../../../Icons/Icons';
import { useNavigate } from 'react-router-dom';
function TakeAppointment() {
    const [step, setStep] = React.useState(1)
    const [error, setError] = React.useState('')
      const navigate = useNavigate()
      const PayConsultation = useCustomMutation({
        mutationFn: client.Consultants.pay,
        onSuccess: (data) => {
          navigate('/my-requests')
        },
        onError: (data) => {
            setError(data.message || data.errors[0]?.message);
            setTimeout(() => setError(''), 5000);
        },
      })    
    const validationSchema = Yup.object({
        lawyer: Yup.string().required(),
        type: Yup.string().required('type is required'),
        specialty: Yup.string().required('specialty is required'),
        date: Yup.string().required('date is required'),
        time: Yup.string().required('time is required'),
    });
    
    const consultationMutation = useCustomMutation({
        mutationFn: client.Consultants.create_consultants,
        queryToInvalidate: {
            queryKey: [API_ENDPOINTS.GET_PROFILE],
          },
        onSuccess: (data) => {
          PayConsultation.mutate(data.data._id)
        },
        onError: (data) => {
          setError(data.message || data.errors[0]?.message);
          setTimeout(() => setError(''), 5000);
        },      
      }
    )
    let Formik = useFormik({
      initialValues:{
        lawyer: '',
        type: 'reservation',
        specialty: '',
        date: '',
        time: '',
        attachments: [],
        description: '',
      },
      validationSchema,
      onSubmit:(values)=>{
        if(values.time.split('PM').length===2){
          values.time = values.time.split('PM')[0]+' PM'
        }
        if(values.time.split('AM').length===2){
          values.time = values.time.split('AM')[0]+' AM'
        }
        const dateTimeString = `${values.date} ${values.time}`;
        const date = new Date(dateTimeString);
        const isoString = date.toISOString();
        const formData = new FormData();
        formData.append('lawyer', values.lawyer);
        formData.append('type', values.type);
        formData.append('specialty', values.specialty);
        formData.append('reservationDate', isoString);
        formData.append('attachments', values.attachments);
        formData.append('description', values.description);
        consultationMutation.mutate(formData)
        Formik.resetForm()
      }
    })
    const stepOne = Formik.values.specialty && Formik.values.type 
    const stepThree = Formik.values.attachments.length>0 && Formik.values.description && Formik.values.date && Formik.values.time
    return <>
    {step!==1 && 
      <button onClick={()=> setStep(step-1)} className='absolute top-0 translate-y-1/2 p-1 bg-second-card rounded-full'><ArrowForward color='#fff'/></button>
    }
    <form onSubmit={Formik.handleSubmit}>
      {step===1 && <TakeAppointmentForm Formik={Formik} disabled={!stepOne} setStep={setStep}/>}
      {step===2 && <FindLawyer Formik={Formik} setStep={setStep}/>}
      {step===3 && <SendConsultant isPending={consultationMutation.isPending} error={error} Formik={Formik} disabled={stepThree}/>}

    </form>
    </>
}

export default TakeAppointment;