import React, { useEffect } from 'react'

import * as Yup from 'yup'
import { useFormik } from 'formik'

import { useTranslation } from 'react-i18next';
import { useCustomMutation } from '../../hooks/use-custome-mutation';
import { useMe } from '../../data/user';
import { useCities, useGovernorates, useSpecialties  } from '../../data/vistor';

import client from '../../client';
import InputField from '../Shared/InputField';

import { CircularProgress } from '@mui/material';
import WorkTimes from './WorkTime/WorkTimes';
import { Link } from 'react-router-dom';

const lawyerType = [
    { label: 'محامى أ', value: 1 },
    { label: 'محامى ب', value: 2 },
    { label: 'محامي محكمة كلية', value: 3 },
    { label: 'محامى استئناف', value: 4 },
    { label: 'محامى دستورية والتمييز', value: 5 },
]
const yesOrNo = [
    { label: 'نعم', value: 1 },
    { label: 'لا', value: 0 },
]
const validationSchema = Yup.object({
    email: Yup.string().required('Email is required'),
    name: Yup.string().required('name is required'),
    phone: Yup.string().required('phone is required'),
    rank: Yup.string().required('rank is required'),
    governorate: Yup.string().required('governorate is required'),
    city: Yup.string().required('city is required'),
    isAvailableToVisit: Yup.bool().required('isAvailableToVisit is required'),
    isAvailableToCall: Yup.bool().required('isAvailableToCall is required'),
    about: Yup.string().required('about is required'),
    // bankName: Yup.string().required('bankName is required'),
    // bankAccountName: Yup.string().required('bankAccountName is required'),
    // AccountNumber: Yup.string().required('AccountNumber is required'),
});


function PersonalInfoFrom() {
    const { t } = useTranslation();
    const { me } = useMe();

    const [error, setError] = React.useState('');
    const [success, setSuccess] = React.useState('');

    const [cities, setCities] = React.useState([]);
    const [governorate, setGovernorate] = React.useState([]);

    const [selectedGovernorate, setSelectedGovernorate] = React.useState(me?.governorate);

    const { data: APIGovernorate } = useGovernorates();
    const { data: APICities } = useCities(selectedGovernorate?.label || me?.governorate);
    const { data: specialties } = useSpecialties()
    const [selectedUserIds, setSelectedUserIds] = React.useState([]);
    React.useEffect(()=>{
        if(me?.specialties.length > 0) {
            setSelectedUserIds([...me?.specialties.map(item=> item.key)])
        }
    },[me])
    const handleCheckboxChange = (userId) => {
        setSelectedUserIds((prevSelectedUserIds) => {
          if (prevSelectedUserIds.includes(userId)) {
            return prevSelectedUserIds.filter((id) => id !== userId);
          } else {
            return [...prevSelectedUserIds, userId];
          }
        });
      };
    

    let wallet = me?.wallet || {};

    const findIndexWithLabel = (value, arr) => {
        if (!value)
            return 0;

        let index = arr.findIndex(x => x.label === value);
        if (index === -1)
            return 0;

        return index
    }


    const getGovernorateOrCities = (list) => {
        if (!list) return [];
        return list.map((element, idx) => ({ label: element, value: idx }));
    };

    useEffect(() => {
        const newGovernorate = getGovernorateOrCities(APIGovernorate);
        const newCities = getGovernorateOrCities(APICities);

        if (JSON.stringify(newGovernorate) !== JSON.stringify(governorate)) {
            setGovernorate(newGovernorate);
        }

        if (JSON.stringify(newCities) !== JSON.stringify(cities)) {
            setCities(newCities);
        }
    }, [APIGovernorate, APICities, governorate, cities]);
    const LawyerProfileMutation = useCustomMutation({
        mutationFn: client.auth.postProfile,
        onSuccess: (data) => {

            setSuccess('تم التحديث بنجاح')
            setTimeout(() => {
                setSuccess('')
            }, 5000)
        },
        onError: (error) => {
            setError('حدث خطأ ما, الرجاء المحاولة لاحقاً')
            setTimeout(() => {
                setError('')
            }, 5000)
        },
    });



    const PersonalInfoFormik = useFormik({
        enableReinitialize: true,
        initialValues: {
            email: me?.email || '',
            name: me?.name || '',
            phone: me?.phoneNumber || '',
            mainSpecialty:me?.mainSpecialty?.key||'',
            specialties:[...selectedUserIds],
            rank: findIndexWithLabel(me?.rank, lawyerType),
            governorate: findIndexWithLabel(me?.governorate, governorate),
            city: findIndexWithLabel(me?.city, cities),
            isAvailableToVisit: me?.isAvailableToVisit ? 1 : 0,
            isAvailableToCall: me?.isAvailableToCall ? 1 : 0,
            about: me?.about || '',
            priceCall: me?.price?.call || '',
            PriceOther: me?.price?.others || '',
            bankName: wallet.bankAccount?.bankTitle || '',
            bankAccountName: wallet.bankAccount?.username || '',
            AccountNumber: wallet.bankAccount?.IBAN || '',
        },
        validationSchema,
        onSubmit: (values) => {
            const formData = new FormData();
            let isFormDataEmpty = true;
        
            const appendFormData = (key, value, original) => {
                if (value !== original) {
                  formData.append(key, value);
                  isFormDataEmpty = false;
                }
              };
          
              appendFormData('name', values.name, me?.name);
              appendFormData('email', values.email, me?.email);
              appendFormData('phoneNumber', values.phone, me?.phoneNumber);
              appendFormData('about', values.about, me?.about);
              appendFormData('isAvailableToCall', values.isAvailableToCall === 1, me?.isAvailableToCall);
              appendFormData('isAvailableToVisit', values.isAvailableToVisit === 1, me?.isAvailableToVisit);
              appendFormData('rank', lawyerType[values.rank].label, me?.rank);
              appendFormData('governorate', governorate[values.governorate]?.label, me?.governorate);
              appendFormData('city', cities[values.city]?.label, me?.city);
              appendFormData('mainSpecialty', values.mainSpecialty, me?.mainSpecialty?.key);
              appendFormData('price.call',values.priceCall,me?.price?.call)
              appendFormData('price.others',values.PriceOther,me?.price?.other)
              appendFormData('wallet.bankAccount.bankTitle',values.bankName,wallet.bankAccount?.bankTitle)
              appendFormData('wallet.bankAccount.username',values.bankAccountName,wallet.bankAccount?.username)
              appendFormData('wallet.bankAccount.IBAN',values.AccountNumber,wallet.bankAccount?.IBAN)
            if (selectedUserIds) {
                selectedUserIds.forEach((specialty, index) => {
                formData.append(`specialties[${index}]`, specialty);
                isFormDataEmpty = false;
                });
            }          
            if (!isFormDataEmpty) {
                LawyerProfileMutation.mutate(formData);
            } else {
            }
        },
            })

    const handleOnChange = (event) => {
        if (event.target.id === 'governorate') {
            let idx = event.nativeEvent.target.value;
            let value = governorate[idx];
            setSelectedGovernorate(value);
        }
    }

    return (
        <div>
            <form onSubmit={PersonalInfoFormik.handleSubmit} onChange={handleOnChange}>
                <div className="flex flex-col gap-12">
                    {/* Main Information */}
                    <div className='bg-second-card border-white border-2 rounded-xl py-8 md:px-11 px-5 text-white'>
                        <div className='flex flex-col gap-5'>
                            <h2 className='font-semibold leading-5'>{t('main_information')}</h2>
                            <div className='flex flex-col gap-[10px]'>
                                <div className='grid lg:grid-cols-2 grid-cols-1 gap-4'>
                                    <InputField
                                        name={'name'}
                                        label={t('input_name')}
                                        onBlur={PersonalInfoFormik.handleBlur}
                                        onChange={PersonalInfoFormik.handleChange}
                                        value={PersonalInfoFormik.values.name}
                                        IsError={PersonalInfoFormik.errors.name && PersonalInfoFormik.touched.name}
                                        error={PersonalInfoFormik.errors.name} />
                                    <InputField
                                        name='email'
                                        type='email'
                                        label={t('input_email')}
                                        onBlur={PersonalInfoFormik.handleBlur}
                                        onChange={PersonalInfoFormik.handleChange}
                                        value={PersonalInfoFormik.values.email}
                                        disabled={true}
                                        IsError={PersonalInfoFormik.errors.email && PersonalInfoFormik.touched.email}
                                        error={PersonalInfoFormik.errors.email}
                                    />
                                </div>
                                <div className='grid lg:grid-cols-2 grid-cols-1 gap-4'>
                                    <InputField
                                        name='phone'
                                        label={t('input_phone')}
                                        disabled={true}
                                        onBlur={PersonalInfoFormik.handleBlur}
                                        onChange={PersonalInfoFormik.handleChange}
                                        value={PersonalInfoFormik.values.phone}
                                        IsError={PersonalInfoFormik.errors.phone && PersonalInfoFormik.touched.phone}
                                        error={PersonalInfoFormik.errors.phone}
                                    />
                                    <InputField
                                        name='rank'
                                        as='select'
                                        value={PersonalInfoFormik.values.rank}
                                        onBlur={PersonalInfoFormik.handleBlur}
                                        onChange={PersonalInfoFormik.handleChange}
                                        IsError={PersonalInfoFormik.errors.rank && PersonalInfoFormik.touched.rank}
                                        error={PersonalInfoFormik.errors.rank}
                                        label={t('input_registration_degree')}
                                        children={lawyerType.map((item, index) => <option key={index} value={item.value} label={item.label}>{item.label}</option>)}
                                    />
                                </div>
                                <div className='grid lg:grid-cols-2 grid-cols-1 gap-4'>
                                    <InputField
                                        name='governorate'
                                        as='select'
                                        value={PersonalInfoFormik.values.governorate}
                                        onBlur={PersonalInfoFormik.handleBlur}
                                        onChange={PersonalInfoFormik.handleChange}
                                        IsError={PersonalInfoFormik.errors.governorate && PersonalInfoFormik.touched.governorate}
                                        error={PersonalInfoFormik.errors.governorate}
                                        label={t('input_governorate')}
                                        children={governorate.map((item, index) => <option key={index} value={item.value} label={item.label}>{item.label}</option>)}
                                    />

                                    <InputField
                                        name='city'
                                        as='select'
                                        value={PersonalInfoFormik.values.city}
                                        onBlur={PersonalInfoFormik.handleBlur}
                                        onChange={PersonalInfoFormik.handleChange}
                                        IsError={PersonalInfoFormik.errors.city && PersonalInfoFormik.touched.city}
                                        error={PersonalInfoFormik.errors.city}
                                        label={t('input_city')}
                                        children={cities.map((item, index) => <option key={index} value={item.value} label={item.label}>{item.label}</option>)}
                                    />
                                </div>
                                <div className='grid lg:grid-cols-2 grid-cols-1 gap-4'>
                                    <InputField
                                        name='isAvailableToVisit'
                                        as='select'
                                        value={PersonalInfoFormik.values.isAvailableToVisit}
                                        onBlur={PersonalInfoFormik.handleBlur}
                                        onChange={PersonalInfoFormik.handleChange}
                                        IsError={PersonalInfoFormik.errors.isAvailableToVisit && PersonalInfoFormik.touched.isAvailableToVisit}
                                        error={PersonalInfoFormik.errors.isAvailableToVisit}
                                        label={t('available_to_visit')}
                                        children={yesOrNo.map((item, index) => <option key={index} value={item.value} label={item.label}>{item.label}</option>)}
                                    />
                                    <InputField
                                        name='isAvailableToCall'
                                        as='select'
                                        value={PersonalInfoFormik.values.isAvailableToCall}
                                        onBlur={PersonalInfoFormik.handleBlur}
                                        onChange={PersonalInfoFormik.handleChange}
                                        IsError={PersonalInfoFormik.errors.isAvailableToCall && PersonalInfoFormik.touched.isAvailableToCall}
                                        error={PersonalInfoFormik.errors.isAvailableToCall}
                                        label={t('available_to_call')}
                                        children={yesOrNo.map((item, index) => <option key={index} value={item.value} label={item.label}>{item.label}</option>)}
                                    />
                                </div>
                                <div className='grid lg:grid-cols-2 grid-cols-1 gap-4'>
                                    <InputField
                                        name={'priceCall'}
                                        label={t('input_consultation_call_price')}
                                        onBlur={PersonalInfoFormik.handleBlur}
                                        onChange={PersonalInfoFormik.handleChange}
                                        value={PersonalInfoFormik.values.priceCall}
                                        IsError={PersonalInfoFormik.errors.priceCall && PersonalInfoFormik.touched.priceCall}
                                        error={PersonalInfoFormik.errors.priceCall} />
                                    <InputField
                                        name='PriceOther'
                                        type='text'
                                        label={t('input_consultation_visit_price')}
                                        onBlur={PersonalInfoFormik.handleBlur}
                                        onChange={PersonalInfoFormik.handleChange}
                                        value={PersonalInfoFormik.values.PriceOther}
                                        IsError={PersonalInfoFormik.errors.PriceOther && PersonalInfoFormik.touched.PriceOther}
                                        error={PersonalInfoFormik.errors.PriceOther}
                                    />
                                </div>
                                <div className='grid lg:grid-cols-2 grid-cols-1 gap-4'>
                                    <InputField
                                        name='mainSpecialty'
                                        as='select'
                                        value={PersonalInfoFormik.values.mainSpecialty}
                                        onBlur={PersonalInfoFormik.handleBlur}
                                        onChange={PersonalInfoFormik.handleChange}
                                        IsError={PersonalInfoFormik.errors.mainSpecialty && PersonalInfoFormik.touched.mainSpecialty}
                                        error={PersonalInfoFormik.errors.mainSpecialty}
                                        label={t('main_specialties')}
                                        children={specialties.map((item, index) => <option key={index} value={item.key} label={item.value}>{item.value}</option>)}
                                    />
                                </div>
                                <div className='grid grid-cols-1 gap-4'>
                                    <InputField
                                        name='about'
                                        as='textarea'
                                        onBlur={PersonalInfoFormik.handleBlur}
                                        onChange={PersonalInfoFormik.handleChange}
                                        value={PersonalInfoFormik.values.about}
                                        IsError={PersonalInfoFormik.errors.about && PersonalInfoFormik.touched.about}
                                        error={PersonalInfoFormik.errors.about}
                                        rows='10'
                                        label={t('about_me')}
                                    />
                                </div>
                                <div>
                                    <label className='pb-5'>{t('specialties')}</label>
                                    <div className='grid grid-cols-4 gap-3'>
                                        {specialties.map((item, index) => 
                                        <div className='flex gap-3 items-center'>
                                            <input 
                                            disabled={selectedUserIds.length>=3 && !selectedUserIds.includes(item.key)}
                                                onChange={() => handleCheckboxChange(item.key)}
                                                checked={selectedUserIds.includes(item.key)}                              
                                                type="checkbox" value="" className="w-4 h-4 text-green-600 focus:text-green-700 bg-gray-100 border-gray-300 rounded"
                                                />
                                            {item.value}
                                        </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
        
                    <WorkTimes/>

                    {/* Bank Info */}
                    <div className='bg-second-card border-white border-2 rounded-xl py-8 md:px-11 px-5 text-white'>
                        

                        <div className='flex items-center justify-between'>
                        <h2 className='pb-5 font-semibold leading-5'>{t('bank_information')}</h2>
                            <Link to='/wallet' className='bg-main rounded-lg py-2 px-3 font-medium'>
                                محفظتي
                            </Link>
                        </div>
                        <div className='grid grid-cols-1 gap-5'>
                            <InputField
                                name='bankName'
                                label={t('input_bank_name')}
                                onBlur={PersonalInfoFormik.handleBlur}
                                onChange={PersonalInfoFormik.handleChange}
                                value={PersonalInfoFormik.values.bankName}
                                IsError={PersonalInfoFormik.errors.bankName && PersonalInfoFormik.touched.bankName}
                                error={PersonalInfoFormik.errors.bankName}
                            />
                            <InputField
                                name='bankAccountName'
                                label={t('input_bank_account_name')}
                                onBlur={PersonalInfoFormik.handleBlur}
                                onChange={PersonalInfoFormik.handleChange}
                                value={PersonalInfoFormik.values.bankAccountName}
                                IsError={PersonalInfoFormik.errors.bankAccountName && PersonalInfoFormik.touched.bankAccountName}
                                error={PersonalInfoFormik.errors.bankAccountName}
                            />
                            <InputField
                                name='AccountNumber'
                                label={t('input_account_number')}
                                onBlur={PersonalInfoFormik.handleBlur}
                                onChange={PersonalInfoFormik.handleChange}
                                value={PersonalInfoFormik.values.AccountNumber}
                                IsError={PersonalInfoFormik.errors.AccountNumber && PersonalInfoFormik.touched.AccountNumber}
                                error={PersonalInfoFormik.errors.AccountNumber}
                            />
                        </div>
                    </div>

                    {/* submit button */}
                    {error && <div className='text-red-600 text-center'>{error}</div>}
                    {success && <div className='text-green-600 text-center'>{success}</div>}
                    <div className='flex justify-center'>
                        <button className={`btn bg-main text-dark px-7 py-4 xl:w-[30%] w-[60%] rounded-xl`} type='submit'>
                            {LawyerProfileMutation.isPending ? <CircularProgress style={{
                                width: '20px',
                                height: '20px',
                                color: '#fff',
                            }} /> : <p className='font-bold'> {t('save')} </p>}
                        </button>
                    </div>

                </div> {/* flex end */}
            </form>
        </div>
    )
}

export default PersonalInfoFrom