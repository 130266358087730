export const API_ENDPOINTS = {
  //Auth 
  LOGIN: '/api/v1/auth/signin',
  SIGN_UP_CLIENT:'/api/v1/auth/signup/client',
  SIGN_UP_LAWYER:'/api/v1/auth/signup/lawyer',
  LOGOUT: '/api/v1/logout',
  GET_PROFILE: '/api/v1/auth/profile',
  VERIFY: '/api/v1/auth/verification-code/verify',
  RESEND: '/api/v1/auth/verification-code/resend',
  RECOVERY:'/api/v1/auth/forget-password',
  //User
  GET_ALL_USERS: '/api/v1/auth/users',
  GET_USER: (id) =>`/api/v1/auth/users/${id}`,
  //Plans
  GET_ALL_PLANS: '/api/v1/plans',
  SUBSCRIPT_PLAN: (id) =>`/api/v1/plans/${id}/subscripe`,
  // Terms and Conditions
  GET_ALL_TERMS: '/api/v1/terms-conditions',
  // About application
  GET_ALL_ABOUT_APP: '/api/v1/about-app',
  // Advertisement
  GET_ALL_ADVERTISEMENTS: '/api/v1/ads',
  //consultants 
  CREATE_CONSULTANTS: '/api/v1/consultants',
  GET_ALL_CONSULTANTS: '/api/v1/consultants',
  GET_CONSULTATION: (id) => `/api/v1/consultants/${id}`,
  PAY_CONSULTATION: (id) => `/api/v1/consultants/pay/${id}`,
  UPDATE_CONSULTATION: (id) => `/api/v1/consultants/${id}`,
  RATE:(id)=> `/api/v1/consultants/${id}/rate`,
  // Static Data 
  GET_SPECIALTIES: '/api/v1/specialties',
  GET_CITIES: '/api/v1/cities',
  // Submit a ticket
  POST_TICKET: '/api/v1/tickets',
  //Lawyer
  GET_ALL_LAWYERS: '/api/v1/auth/users?type=lawyer',
  //Notifications
  GET_ALL_NOTIFICATIONS: '/api/v1/notifications',
  //Wallet
  GET_WALLET: 'api/v1/auth/wallet',
  //Transaction
  TRANSACTION: '/api/v1/transactions?type=user-to-user',
  TRANSITIONS: '/api/v1/transitions',
  
  // work time
  WORK_TIME: 'api/v1/work-times',
  WORK_TIME_ID:(id)=> `/api/v1/work-times/${id}`,
  WORK_TIME_PERIOD: (workTimeId ,periodId )=>`api/v1/work-times/${workTimeId}/periods/${periodId}`,
  GET_PERIODS_BY_DATE: (id)=> `/api/v1/work-times/${id}/periods`
};