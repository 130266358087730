import React from 'react'
import { useTranslation } from 'react-i18next';
import { Outlet } from 'react-router-dom';

export default function AuthLayout() {
    const { t } = useTranslation();
  return (
    <div className='grid md:grid-cols-2 bg-second min-h-screen'>
        <div className='hidden md:block col-span-1 relative rounded-e-[40px] overflow-hidden'>
            <img className='w-full h-full object-cover' src="/Images/auth-image.jpg" alt="" />
            <div className='absolute inset-0 bg-[#020914] opacity-50'></div>
            <div className='absolute inset-0 flex flex-col items-center justify-center text-white gap-12'>
              <h1 className='text-6xl font-semibold'>{t('onboarding_company_name')}</h1>
              <h2 className='text-4xl font-medium text-center max-w-[450px] leading-[200%]'>{t('onboarding_description')}</h2>
            </div>
        </div>
        <div className='col-span-1 flex items-center justify-center'>
          <Outlet/>
        </div>
    </div>
  )
}
