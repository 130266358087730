import React from 'react'
import CameraAltOutlinedIcon from '@mui/icons-material/CameraAltOutlined';
import { useMe } from '../../data/user';
import { useCustomMutation } from '../../hooks/use-custome-mutation';
import client from '../../client';
import { Share } from '@mui/icons-material';
import ShareLink from '../../Pages/Shared/ShareLink';

function PersonalImage() {
    const [openModel, setOpenModel] = React.useState(false)
    const { me } = useMe()
    const shareUrl = `${process.env.REACT_APP_DOMAIN}/lawyer/${me?._id}`
    const [ProfileImage, setProfileImage] = React.useState(me?.profileImage)
    const LawyerProfileMutation = useCustomMutation({
        mutationFn: client.auth.postProfile,
        onSuccess: (data) => {
        },
        onError: (error) => {

        },
    });

    const handleImageChange = (e) => {
        const image = e.target.files[0]
        if (image) {
            const formData = new FormData()
            formData.append('profileImage', image)
            LawyerProfileMutation.mutate(formData)
            setProfileImage(URL.createObjectURL(image))
        }
    }

    return (
        <div className='bg-second-card border-white border-2 rounded-xl py-7 relative'>
            <div className='flex justify-center items-center '>
                <div className='relative w-[100px] h-[100px]'>
                    <div className='absolute top-0 left-0 rounded-full bg-second-card border-white border'>
                        <CameraAltOutlinedIcon className='text-white  transform scale-[0.65]' />
                        <input type="file" className='absolute inset-0 opacity-0' accept="image/*" onChangeCapture={(e) => { handleImageChange(e) }} />
                    </div>
                    <img className='w-full h-full  rounded-full border-white border ' src={ProfileImage || me?.profileImage} alt="" />
                </div>
            </div>
            {me?.type==='lawyer' && 
            <div className="absolute top-3 left-3">
              <Share onClick={()=>setOpenModel(true)} className="text-white cursor-pointer"/>
            </div>
            }
            <ShareLink openModel={openModel} setOpenModel={setOpenModel} shareUrl={shareUrl}/>
        </div>
    )
}

export default PersonalImage