import React from 'react'

function AchievementCart({title, number, icon}) {
  return (
    <div className='text-center text-white flex flex-col gap-1 items-center justify-center'>
    {/* <FeedIcon className='text-main' /> */}
    <img src={icon} alt="" className=' lg:w-32 md:w-24  w-12 ' />
    <h3 className='lg:text-1xl md:text-lg font-medium lg:mb-7 mb-2'>{title}</h3>
    <h3 className='lg:text-5xl text-3xl font-medium '>+{number}</h3>
</div>
  )
}

export default AchievementCart