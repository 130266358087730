import { Navigate } from 'react-router-dom';
import { useAuth } from './Auth/context';


const AuthRoute = ({ children }) => {
  const { authType } = useAuth();

  return authType ? children : <Navigate to={'/auth'} />;
};

export default AuthRoute;
