import { useQuery } from '@tanstack/react-query';
import client from '../client';
import { API_ENDPOINTS } from '../client/endpoints';
import { useAuth } from '../Components/Auth/context';
import { useNavigate } from 'react-router-dom';
import { removeAuthToken } from '../client/token-utils';

export const useMe = () => {
  const navigate = useNavigate()
  const { isAuthorized } = useAuth();
  const { isPending, data, isError, error } = useQuery({
    queryKey: [API_ENDPOINTS.GET_PROFILE],
    queryFn: client.auth.getProfile,
    gcTime: 0,
  });
  if(error?.message ==='token expired'){
    removeAuthToken()
    navigate('/auth/login')
  }
  return {
    isPending,
    isError,
    error,
    isAuthorized,
    me: data?.data,
  };
};
