import React from 'react'
import ConsultationCard from './ConsultationCard';
import { useConsultant } from '../../../data/vistor';
import { Link, useParams } from 'react-router-dom';
import { useMe } from '../../../data/user';
import { useCustomMutation } from './../../../hooks/use-custome-mutation';
import client from '../../../client';
import { API_ENDPOINTS } from '../../../client/endpoints';
import LoadingData from '../../../Components/Shared/LoadingData';
import { CircularProgress, Modal } from '@mui/material';

function ConsultationDetails() {
    const {id} = useParams()
    const [openModel, setOpenModel] = React.useState(false)
    const update = useCustomMutation({
        mutationFn:client.Consultants.update,
        queryToInvalidate: {
            queryKey: [API_ENDPOINTS.GET_CONSULTATION(id)],
        },
        onSuccess: (data) => {
        },
        onError : (err) => {
            if(err.code === 403){
                setOpenModel(true)
            }
        }
    })
    const {data , isPending} = useConsultant(id)
    const {me} = useMe()
    return (
        <div className='bg-second h-full py-16'>
            <div className='container mx-auto'>
                <div className='flex flex-col justify-center gap-10'>
                    {isPending?
                    <LoadingData/>:<>
                    <ConsultationCard consultationDetails={data}/>
                    {me?.type ==='lawyer' && data.status ==='active' && 
                    <div className='pt-10 flex justify-center'>
                        <button  onClick={()=> update.mutate({
                            id,
                            status:'finished'
                        })} className={`btn bg-main text-dark px-7 py-5 xl:w-[50%] w-[60%] rounded-md`} type='submit'>
                            <p className='font-semibold text-sm leading-4'>{update.isPending?<CircularProgress style={{
                                    width: '20px',
                                    height: '20px',
                                    color: '#fff',
                                }} />:'تحويل الي مكتملة'} </p>
                        </button>
                    </div>
                    }
                    </>    
                }
                </div>
                <Modal open={openModel} onClose={()=> setOpenModel(false)}>
                    <div className="bg-second-card absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 px-16 md:px-32 py-10 rounded-3xl shadow-2xl max-h-[600px] overflow-auto">
                        <div className='flex flex-col gap-6 items-center'>
                            <h2 className='text-main text-3xl font-bold'>اشترك في خطة اولاً</h2>
                            <Link to='/subscriptionplans' className='bg-main py-2 px-5 rounded-md font-medium text-lg'>للاشتراك</Link>

                        </div>
                    </div>
                </Modal>
            </div>

        </div>
    )
}

export default ConsultationDetails