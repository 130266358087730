import React from 'react'

import ServiceCard from './ServiceCard'


import serviceBg from '../../../assets/images/services.jpg';

import serviceBest from '../../../assets/images/serviceBest.svg';
import serviceSearch from '../../../assets/images/serviceSearch.svg';
import serviceConsultant from '../../../assets/images/serviceConsultant.svg';

import './Services.css'
import { useTranslation } from 'react-i18next';

function Services() {
  const { t } = useTranslation();
  return (
    <div id='services' className='pb-1 relative bg-cover bg-top' style={{ backgroundImage: `url(${serviceBg})` }}>
      <div className="services-content relative">
        <div className='lg:container mx-auto'>
          <div className='flex justify-center items-center pt-4'>
            <div className='w-0 border-main h-20'></div>
            <div className='w-0 border-main h-20 mx-7'></div>
            <div className='w-0 border-main h-20'></div>
          </div>
          <div className='flex justify-center items-center flex-col my-20'>
            <h2 className='text-4xl font-semibold text-white'>{t('our_services')}</h2>
            <p className='xl:w-[39%] lg:w-[60%] text-center text-white md:text-lg text-xs mt-6 sm:mx-10 mx-3 md:mx-0'>{t('services_description')}</p>
          </div>
          <div className='lg:container mx-auto mb-20 ' >
            <div className='grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 justify-between content-center xl:gap-x-20 md:gap-x-3'>
              <ServiceCard title={'امكانية البحث عن  محامي'} description={'نقوم بتزويدكم بالمعلومات القانونية التي تساعد في الإختيار الصحيح لنوع الشركة ومن ثم نقوم بالنيابة عنكم بخطوات'} icon={serviceSearch} />
              <ServiceCard title={'افضل المحامين'} description={'نقدم الإستشارات القانونية وذلك من خلال تقديم المشورة القانونية أو الرأى القانوني وتقديم الحلول القانونية'} icon={serviceBest} />
              <ServiceCard title={'تقديم الاستشارات'} description={'نقوم بتزويدكم ب المعلومات القانونية التي تساعد في الإختيار الصحيح ل نوع الشركة ومن ثم نقوم نيابة عنكم بخطوات'} icon={serviceConsultant} />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Services