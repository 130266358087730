import React, { useRef } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { API_ENDPOINTS } from '../../../client/endpoints';
import { useAuth } from '../../../Components/Auth/context';
import client from '../../../client';
import { CircularProgress } from '@mui/material';
import { useCustomMutation } from '../../../hooks/use-custome-mutation';
import { useTranslation } from 'react-i18next';
import Input from '../../../Components/Auth/Forms/Input';
import { useCounterDown } from '../../../hooks/use-counter-down';

export default function OTP() {
  const { t } = useTranslation();
  const [error, setError] = React.useState('');
  const [message, setMessage] = React.useState('');
  const [time, setTime] = React.useState(0);
  const navigate = useNavigate();
  const { authorize } = useAuth();

  const [searchParams] = useSearchParams();
  const user = searchParams.get('user');

  const counter = useCounterDown(time);
  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
  };
  
  const inputRefs = useRef([]);

  let validationSchema = Yup.object({
    num1: Yup.string().required(),
    num2: Yup.string().required(),
    num3: Yup.string().required(),
    num4: Yup.string().required(),
    num5: Yup.string().required(),
    num6: Yup.string().required(),
  });

  const otpMutation = useCustomMutation({
    mutationFn: client.auth.verify,
    queryToInvalidate: {
      queryKey: [API_ENDPOINTS.GET_PROFILE],
    },
    onSuccess: (data) => {
      if (data.data.token) {
        authorize(data.data);
        if(data.data.userType==='lawyer'){
          navigate('/subscriptionplans');
        }else{
          navigate('/');
        }
      }else{
        navigate(`/auth/resetPassword?user=${user}`)
      }
    },
    onError: (data) => {
      setError(data.message || data.errors[0]?.message);
      setTimeout(() => setError(''), 5000);
    },
  });

  const resendCode = useCustomMutation({
    mutationFn: client.auth.resend,
    onSuccess: (data) => {
      if (data.status) {
        setMessage(data.message);
      } else {
        setError(data.message);
        setTime(data.data.remainingTime);
      }
      setTimeout(() => {
        setError('');
        setMessage('');
      }, 5000);
    },
    onError: (data) => {
      setError(data.message || data.errors[0]?.message);
      setTimeout(() => setError(''), 5000);
    },
  });

  let verifyFormik = useFormik({
    initialValues: {
      user: user || '',
      num1: '',
      num2: '',
      num3: '',
      num4: '',
      num5: '',
      num6: '',
    },
    validationSchema,
    onSubmit: (values) => {
      otpMutation.mutate({
        user: values.user,
        code: `${values.num1}${values.num2}${values.num3}${values.num4}${values.num5}${values.num6}`
      });
    },
  });

  const handlePaste = (e) => {
    const pastedData = e.clipboardData.getData('Text');
    if (pastedData.length === 6 && /^\d+$/.test(pastedData)) {
      const updatedValues = {};
      pastedData.split('').forEach((value, index) => {
        updatedValues[`num${index + 1}`] = value;
        inputRefs.current[index].value = value;
        if (index < inputRefs.current.length - 1) {
          inputRefs.current[index + 1].focus();
        }
      });
      verifyFormik.setValues({
        ...verifyFormik.values,
        ...updatedValues,
      });
    }
    e.preventDefault(); 
  };

  const handleChange = (e, index) => {
    const value = e.target.value;
    verifyFormik.handleChange(e);
    if (value.length === 1 && index < inputRefs.current.length - 1) {
      inputRefs.current[index + 1].focus();
    }else if (value.length === 0 && index > 0) {
      inputRefs.current[index - 1].focus();
    }

  };
  return (
    <div className="flex flex-col gap-y-8 lg:p-8 lg:w-[480px]">
      <div className="font-semibold text-[30px] text-white text-center leading-[55px] tracking-tight">
        {t('check_otp_code')}
      </div>
      <form onSubmit={verifyFormik.handleSubmit} className="flex flex-col gap-9">
        <div className="p-[10px] flex flex-col items-center gap-[10px]">
          <h3 className="text-[#FBF3E9] leading-7">{t('send_opt_code')}</h3>
          <h3 className="text-[#FBF3E9] leading-7">{user}</h3>
        </div>
        <div className='grid flex-row-reverse grid-cols-6 gap-2' dir='ltr'>
          {[...Array(6)].map((_, index) => (
            <Input
              key={index}
              ref={(el) => (inputRefs.current[index] = el)}
              onBlur={verifyFormik.handleBlur}
              onChange={(e) => handleChange(e, index)}
              onPaste={index === 0 ? handlePaste : undefined} // Add the paste handler to the first input
              value={verifyFormik.values[`num${index + 1}`]}
              name={`num${index + 1}`}
              className='!text-4xl !p-2 text-center'
              type={'otp'}
            />
          ))}
        </div>
        <div>
          <p className="font-semibold flex justify-center gap-2 items-center text-muted">
            {counter > 0 ?
              <>
                <span className='text-white'>
                  {formatTime(counter)}
                </span>
                <span className='text-main'>
                  {`${t('resend_again')}`}
                </span>
              </>
              :
              <>
                {t(`code_didn't_sent`)}
                <span onClick={() => resendCode.mutate({ user })} className="text-main cursor-pointer">
                  {t('resend_again')}
                </span>
              </>
            }
          </p>
        </div>
        {error && <div className="text-red-500 text-center">{error}</div>}
        {Object.keys(verifyFormik.errors).length > 0 && <div className="text-red-500 text-center">{'كود التحقق يجب ان يتكون من 6 ارقام'}</div>}
        {message && <div className="text-green-600 text-center">{message}</div>}
        <div>
          <button
            disabled={!(verifyFormik.isValid && verifyFormik.dirty) || otpMutation.isPending}
            className="bg-main font-semibold text-second w-full px-2 py-3 rounded-xl"
          >
            {otpMutation.isPending ? (
              <CircularProgress
                style={{
                  width: '20px',
                  height: '20px',
                  color: '#fff',
                }}
              />
            ) : (
              t('send')
            )}
          </button>
        </div>
      </form>
    </div>
  );
}
