import React from 'react'
import { useTranslation } from 'react-i18next';
import StaticInputField from '../../../Shared/StaticInputField';
import TransactionAmountForm from './TransactionAmountForm';

function WalletForm({ data }) {
    const { t } = useTranslation();
    const [isVisible, setIsVisible] = React.useState(false);

    const makeTransaction = () => {
        setIsVisible(true);
    }
    return (
        <div className="flex flex-col gap-12 relative">
            <div className='absolute w-1/2 top-1/4 right-1/4'>
                <TransactionAmountForm limit={data?.withdrawBalance} setVisible={setIsVisible} visible={isVisible} />
            </div>
            <div className='bg-second-card border-white border-2 rounded-xl py-8 md:px-11 px-5 text-white'>
                <div className='flex flex-col gap-5'>
                    <div className='flex flex-row items-center justify-between'>
                        <h2 className='font-semibold leading-5'>{t('my_wallet')}</h2>
                        <div className='flex gap-4'>
                            <button className={`btn bg-main text-white-50 p-2 rounded-md`} onClick={() => { makeTransaction() }}>
                                <p className='font-bold text-xs'> {t('withdraw_balance')} </p>
                            </button>
                            <button className={`btn bg-[#395875] text-second p-2 rounded-md`} onClick={() => { }}>
                                <p className='font-bold text-xs'> {t('deposit_balance')} </p>
                            </button>
                        </div>

                    </div>
                    <div className='flex flex-col gap-[10px]'>
                        <StaticInputField value={data?.withdrawBalance} label={t('input_withdrawable_balance')} />
                        <StaticInputField value={data?.holdBalance} label={t('input_suspended_balance')} />
                        <StaticInputField value={data?.totalBalance} label={t('input_total_balance')} />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default WalletForm