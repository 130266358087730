import { API_ENDPOINTS } from './endpoints';
import { HttpClient } from './http-client';

class Client {
  constructor() {
    this.auth = {
      getProfile: () =>
        HttpClient.get(API_ENDPOINTS.GET_PROFILE),
      postProfile: (input) =>
        HttpClient.patch(API_ENDPOINTS.GET_PROFILE, input),
      login: (input) =>
        HttpClient.post(API_ENDPOINTS.LOGIN, input),
      signUpClient: (input) =>
        HttpClient.post(API_ENDPOINTS.SIGN_UP_CLIENT, input),
      signUpLawyer: (input) =>
        HttpClient.post(API_ENDPOINTS.SIGN_UP_LAWYER, input),
      verify: (input) =>
        HttpClient.post(API_ENDPOINTS.VERIFY, input),
      resend: (input) =>
        HttpClient.post(API_ENDPOINTS.RESEND, input),
      recovery: (input) =>
        HttpClient.post(API_ENDPOINTS.RECOVERY, input),
      reset_password: (input) =>
        HttpClient.put(API_ENDPOINTS.RECOVERY, input),
    };

    this.Users = {
      get_all: (data) =>
        HttpClient.get(API_ENDPOINTS.GET_ALL_USERS,
          {
            params: data,
          }
        ),
      get: (id) =>
        HttpClient.get(API_ENDPOINTS.GET_USER(id)),
    };
    this.Plans = {
      get_all: () =>
        HttpClient.get(API_ENDPOINTS.GET_ALL_PLANS),
      subscript: (planId)=>
      {
       return HttpClient.get(API_ENDPOINTS.SUBSCRIPT_PLAN(planId))
      }
    }
    this.Advertisement = {
      get_all: () =>
        HttpClient.get(API_ENDPOINTS.GET_ALL_ADVERTISEMENTS),
    }
    this.TermsConditions = {
      get_all: () =>
        HttpClient.get(API_ENDPOINTS.GET_ALL_TERMS),
    }
    this.AboutApp = {
      get_all: () =>
        HttpClient.get(API_ENDPOINTS.GET_ALL_ABOUT_APP),
    }
    this.Consultants = {
      create_consultants: (data) =>
      HttpClient.post(API_ENDPOINTS.CREATE_CONSULTANTS,data),
      get_all: (data) => 
       HttpClient.get(API_ENDPOINTS.GET_ALL_CONSULTANTS,
          {
            params: data,
          }
        ),
      get: (id) =>
        HttpClient.get(API_ENDPOINTS.GET_CONSULTATION(id)),
      update: (data) =>
        HttpClient.patch(API_ENDPOINTS.UPDATE_CONSULTATION(data.id), data),
      pay: (id) =>
        HttpClient.get(API_ENDPOINTS.PAY_CONSULTATION(id)),
      rate: (data) =>
        HttpClient.post(API_ENDPOINTS.RATE(data.id),data)
    }
    this.StaticData = {
      get_Governorates: () =>
        HttpClient.get(API_ENDPOINTS.GET_CITIES),
      get_cities:(name) =>
        HttpClient.get(API_ENDPOINTS.GET_CITIES,{params:{key: name}}),
      get_specialties: () =>
        HttpClient.get(API_ENDPOINTS.GET_SPECIALTIES),
    }
    this.Tickets = {
      post_ticket: (input) =>
        HttpClient.post(API_ENDPOINTS.POST_TICKET, input),
    }
    this.Notifications = {
      get_all:(data)=>
        HttpClient.get(API_ENDPOINTS.GET_ALL_NOTIFICATIONS,{params:data}),
    }
    this.Wallet = {
      get_wallet:()=>
        HttpClient.get(API_ENDPOINTS.GET_WALLET),
    }
    this.Transitions= {
      get_all: () =>
        HttpClient.get(API_ENDPOINTS.TRANSITIONS, ),
      post_transitions: (input) =>
        HttpClient.post(API_ENDPOINTS.TRANSITIONS, input),
    }
    this.Transactions = {
      get_all: () =>
        HttpClient.get(API_ENDPOINTS.TRANSACTION ),
    }
    this.Transactions = {
      get_all: () =>
        HttpClient.get(API_ENDPOINTS.TRANSACTION ),
    }
    this.WorkTime = {
      get_work_times: () =>
        HttpClient.get(API_ENDPOINTS.WORK_TIME),
      add_work_time: (input) =>
        HttpClient.post(API_ENDPOINTS.WORK_TIME,input),
      update_work_time: (data) =>
        HttpClient.put(API_ENDPOINTS.WORK_TIME_ID(data.id),data),
      delete_work_time: (id) =>
        HttpClient.delete(API_ENDPOINTS.WORK_TIME_ID(id)),
      update_period: (data) =>
        HttpClient.put(API_ENDPOINTS.WORK_TIME_PERIOD(data.workTimeId ,data.periodId),data),
      delete_period: (data) =>
        HttpClient.delete(API_ENDPOINTS.WORK_TIME_PERIOD(data.workTimeId ,data.periodId)),
      add_period: (data) =>
        HttpClient.patch(API_ENDPOINTS.WORK_TIME_ID(data.id),data),
      get_periods_by_date: (data) => 
        HttpClient.get(
          API_ENDPOINTS.GET_PERIODS_BY_DATE(data.id),{params:{date: data.date}}),
    }
  }
}
// eslint-disable-next-line import/no-anonymous-default-export
export default new Client();

