import React from 'react';
import { useTranslation } from 'react-i18next';
import { Search } from '../../../Icons/Icons';
import LawyerCard from '../../../Components/Client/FindLawyer/LawyerCard';
import { useUsers } from '../../../data/vistor';
import NotFoundData from '../../../Components/Shared/NotFoundData';
import LoadingData from '../../../Components/Shared/LoadingData';

export default function FindLawyer({ Formik, setStep }) {
  const { t } = useTranslation();
  const [pagination, setPagination] = React.useState({
    pageIndex: 0,
    pageSize: 12,
  });
  const [userData, setUserData] = React.useState([]);
  const [searchTerm, setSearchTerm] = React.useState('');
  const [search, setSearch] = React.useState('');

  const { data: fetchedUsers = {}, isPending: isLoadingUsers, isError } = useUsers({
    page: pagination.pageIndex + 1,
    limit: pagination.pageSize,
    search:search===''? null:search,
    type: 'lawyer',
    specialty:Formik.values.specialty,
  });

  React.useEffect(() => {
    if (fetchedUsers.data) {
      if (search) {
        setUserData(fetchedUsers.data);
      } else {
        setUserData((prevUserData) => {
          const newUsers = fetchedUsers.data.filter(
            (newUser) => !prevUserData.some((existingUser) => existingUser._id === newUser._id)
          );
          return [...prevUserData, ...newUsers];
        });
      }
    }

    if (isError) {
    }
  }, [fetchedUsers.data, isError, search]);
    
  React.useEffect(() => {
    const handleScroll = () => {
      const bottom = Math.ceil(window.innerHeight + window.scrollY) >= document.documentElement.scrollHeight;
      if (bottom && !isLoadingUsers && pagination.pageSize < fetchedUsers.pagination?.resultCount) {
        setPagination((prev) => ({
          ...prev,
          pageSize: prev.pageSize + 12,
        }));
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [isLoadingUsers, fetchedUsers, pagination.pageSize]);

  // Debounce the search input
  React.useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      setSearch(searchTerm);
    }, 300);

    return () => clearTimeout(delayDebounceFn);
  }, [searchTerm]);

  return (
    <div className="flex flex-col gap-12 text-white">
      <div className="relative">
        <div className="relative mb-2">
          <div className="absolute inset-y-0 start-0 flex items-center ps-3.5 pointer-events-none">
            <Search />
          </div>
          <input
            type="text"
            onChange={(e) => setSearchTerm(e.target.value)}
            className="input bg-second-card font-medium text-white placeholder:text-[#7B8389] rounded-xl block w-full ps-12 p-4"
            placeholder={t('search_on_lawyer')}
          />
        </div>
      </div>

      {isLoadingUsers ? <LoadingData/>:
      userData.length>0?
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          {userData.map((user) => (
            <LawyerCard key={user._id} user={user} Formik={Formik} setStep={setStep} />
          ))}
        </div>
        :<NotFoundData Message={t('Not_Found_Lawyers')}/>}
    </div>
  );
}
