import React from 'react'
import { useTranslation } from 'react-i18next';
import { useMe } from '../../../../data/user';

function EarningDetailsItem({item}) {
    const {t} = useTranslation();
    const {me} = useMe()
  return (
    <div className='bg-input-second sm:p-7 p-3 !pt-10 rounded-xl relative'>
        <div className=' bg-main rounded-3xl absolute top-1 left-2 p-2'>
            <p className='text-[8px]'>{t('earning_state') + " : " + t(`${item.status}`)}</p>
        </div>
        <div className='flex flex-row items-center justify-between text-white'>
          {item.from._id === me?._id?
            <p>{t('earning_details') + ' : '}<span className='text-main'>  {t('i_transferred_an_amount_of')} {t(`${item.amount}`)} {t('dinar')} {t('to')} {item.to.name}</span></p>
          :  <p>{t('earning_details') + ' : '}<span className='text-main'>  {t('an_amount_of')} {t(`${item.amount}`)} {t('dinar')} {t('from')} {item.from.name}</span></p>
           }
            <p>{item.amount + ' ' + t('dinar')}</p>
        </div>
    </div>
  )
}

export default EarningDetailsItem