import React from 'react'
import { useTranslation } from 'react-i18next';
import FiltrationButton from '../../../Components/Shared/FiltrationButton';
import RequestItem from '../../../Components/Shared/RequestItem';
import { useConsultants } from '../../../data/vistor';
import LoadingData from '../../../Components/Shared/LoadingData';
import NotFoundData from '../../../Components/Shared/NotFoundData';

export default function MyRequests() {
    const { t } = useTranslation();
    const [status, setStatus] = React.useState('active');
    const {data , isPending }= useConsultants({
        status
    })


  return (
    <div className='bg-second h-full pt-16'>
    <div className='container mx-auto'>
        <h1 className='text-white text-xl font-bold leading-5'>{t('my_requests')}</h1>
        <div className='flex flex-row gap-4 py-20'>

        {/* Filtration buttons */}
        <FiltrationButton setStatus={setStatus} status={'active'} text={'نشطة'} isSelected={status==='active'} />
        <FiltrationButton setStatus={setStatus} status={'finished'} text={'مكتملة'} isSelected={status==='finished'} />
        <FiltrationButton setStatus={setStatus} status={'in-progress'} text={'غير مكتملة'} isSelected={status==='in-progress'} />
        </div>
        <div className='flex flex-col gap-8 pb-12'>
            {isPending ? <LoadingData/>:
            data?.data.length>0? data?.data.map((item, index) => {
                return (
                    <RequestItem key={index} item={item} hasButton={true} buttonMain={false} buttonText={t('show_details')} />
                )
            }):<NotFoundData Message={t('Not_Found_Requests')}/>}
        </div>
    </div>
</div>
)
}
