import React from 'react'

export default function Select({name, options, value , label  , onBlur , onChange ,  rightIcon, leftIcon , IsError , error}) {
    return (
    <div className='flex flex-col gap-2'>
        <label htmlFor={name} className='text-muted'>{label}</label>
        <div className='relative'>
            <select className='appearance-none bg-no-repeat w-full ps-6 py-3 rounded-xl font-medium' onBlur={onBlur} onChange={onChange} value={value} name={name} id={name}>
                {options && options.map((item , index)=>
                    <option key={index} value={item.value}>{item.label}</option>
                )}
            </select>
        </div>
        {IsError?<div className='text-red-500'>{error}</div>:''}
    </div>
  )
}
