import React from 'react';
import { Consultant, PDF, Upload } from '../../../Icons/Icons';
import { useTranslation } from 'react-i18next';
import InputField from '../../../Components/Shared/InputField';
import { usePeriodsByDate } from '../../../data/vistor';
import { CircularProgress } from '@mui/material';

const RenderFileName = ({ file }) => {
    const { t } = useTranslation(); 

    const handleShowFile = () => {
        const fileURL = URL.createObjectURL(file);
        window.open(fileURL, '_blank');
    };

    if (file.type === 'application/pdf') {
        return (
            <div className='flex gap-4'>
                <div className='w-10 h-10 rounded-xl overflow-hidden'>
                    <PDF />
                </div>
                <div className='flex justify-between items-center w-4/5 py-1'>
                    <div>{file.name}</div>
                    <button className='text-main' onClick={handleShowFile}>
                        {t('show')}
                    </button>
                </div>
            </div>
        );
    } else if (file.type.startsWith('image/')) {
        return (
            <div className='flex gap-4'>
                <div className='w-10 h-10 rounded-xl overflow-hidden'>
                    <img src={URL.createObjectURL(file)} className='w-full h-full' alt='' />
                </div>
                <div className='flex justify-between items-center w-4/5 py-1'>
                    <div>{file.name}</div>
                    <button className='text-main' onClick={handleShowFile}>
                        {t('show')}
                    </button>
                </div>
            </div>
        );
    } else {
        return <div className='text-center'>Unknown File</div>;
    }
};

export default function SendConsultant({ Formik  ,error, isPending}) {
    const { t } = useTranslation(); 
    const { data  } = usePeriodsByDate({
        id: Formik.values.lawyer,
        date: Formik.values.date,
    })

    // Function to display file name based on its type
    return (
        <div className="flex flex-col gap-12 text-white ">
            <div className='p-3 bg-second-card rounded-xl flex'>
                <div><Consultant /></div>
                <div className='flex flex-col gap-1'>
                    <h4 className='text-main text-sm font-normal'>جنائي</h4>
                    <h3 className='font-medium'>محمد أحمد</h3>
                </div>
            </div>
            <div className='px-11 pt-8 pb-16 bg-second-card rounded-xl flex flex-col gap-5'>
                <InputField
                    onBlur={Formik.handleBlur}
                    onChange={Formik.handleChange}
                    value={Formik.values.date}
                    IsError={Formik.errors.date && Formik.touched.date} 
                    error={Formik.errors.date}  
                    name='date'
                    type='date'
                    label={t('input_consultation_date')}
                />
                <InputField
                onBlur={Formik.handleBlur}
                onChange={Formik.handleChange}
                value={Formik.values.time}
                IsError={Formik.errors.time && Formik.touched.time} 
                error={Formik.errors.time}  
                name='time'
                as='select'
                label={t('input_consultation_time')}
                children={<>
                <option value=''>{t('input_consultation_time')}</option>
                {data?.data?.periods?.map((item, index) => (
                    <option key={index} value={item.fromTime}>{item.fromTime}</option>
                ))}
                </>}
            />

                <div className='flex flex-col gap-2 w-full'>
                    <label className='leading-5 text-sm'>{t('description')}</label>
                    <InputField
                        as='textarea' 
                        onBlur={Formik.handleBlur} 
                        onChange={Formik.handleChange}
                        value={Formik.values.description}
                        name='description'                                    
                        type='text' 
                        placeholder={t('description_placeholder')} 
                        rows={5} 
                        className={`text-[#92A0AF] w-full bg-input-second rounded-2xl py-6 px-8 focus:outline-none`} 
                    />
                </div>
                <div className='flex flex-col gap-2'>
                    <label className='leading-5 text-sm'>{t('upload_required_files')}</label>
                    <div className="relative mb-2">
                        <div className="absolute inset-y-0 start-0 flex items-center ps-3.5 pointer-events-none z-20">
                            <Upload />
                        </div>
                        <div className="absolute inset-0 bg-input-second z-10 rounded-xl flex items-center pointer-events-none">
                            <p className='text-center ps-14 font-normal text-[#fff]'>{t('upload_files')}</p>
                        </div>
                        <input
                            onBlur={Formik.handleBlur}
                            onChange={(event) => {
                                const files = Array.from(event.currentTarget.files);
                                Formik.setFieldValue('attachments', files);
                            }}
                            name="attachments"
                            type="file"
                            multiple
                            className="input bg-input-second font-medium text-white placeholder:text-[#fff] rounded-xl block w-full ps-12 p-4"
                            placeholder={t('ارفع مرفقات')}
                        />
                    </div>
                </div>
                <div className='flex flex-col gap-2'>
                    {Formik.values.attachments && Formik.values.attachments.map((file, index) => (
                        <RenderFileName key={index} file={file}/>
                    ))}
                </div>
            </div>
            {error && <div className="text-red-500 text-center">{error}</div>}
            <div className='flex justify-center'>
                <button type='submit' className='bg-main rounded-xl py-3 px-32 font-medium text-black'>
                    {isPending?<CircularProgress style={{
                                    width: '20px',
                                    height: '20px',
                                    color: '#fff',
                                }} /> : t('request_consultation')}
                </button>
            </div>
        </div>
    );
}
