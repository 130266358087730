import React from 'react'
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

function PlanItem({ title, isMiddle }) {
    return (
        <div className='flex flex-row gap-3 justify-items-start items-center font-bold'>
             <CheckCircleIcon className={`${isMiddle? 'text-[#272014]': 'text-white-50'}`} sx={{fontSize: '30px'}} /> 
             {/* :<CancelIcon className={`${isMiddle? 'text-[#272014]': 'text-white-50'}`} sx={{fontSize: '30px'}} />} */}
            <h3 className={` md:text-sm text-xs text-start ${isMiddle? 'text-[#272014]': 'text-white-50'}`}>{title}</h3>
        </div>
    )
}

export default PlanItem