import React from 'react'
import footerIcon from './footerLogo.svg'
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import InstagramIcon from '@mui/icons-material/Instagram';
import TwitterIcon from '@mui/icons-material/Twitter';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
function FooterComponent() {
    const { t } = useTranslation();
    return (
        <footer className='bg-dark '>
            <div className='container mx-auto'>
                <div className='grid grid-rows-3 lg:grid-rows-1 md:grid-cols-12 gap-5 py-5 justify-items-stretch content-center'>
                    <div className='lg:col-span-3 flex justify-center  md:my-auto w-full'>
                        <img src={footerIcon} alt="" className=' md:w-[75px] w-[50px]' />
                    </div>
                    <div className='lg:col-span-6 my-auto'>
                        <div className='grid grid-cols-1 justify-items-center content-center text-white'>
                            <div className='grid grid-cols-3 gap-3 text-center justify-items-center content-center text-xs'>
                                <Link to=''>
                                    <p>{t('call_us')} </p>
                                </Link>
                                <Link to=''>
                                    <p className='md:ml-5 sm:ml-2'>{t('conditions')}</p>
                                </Link>
                                <Link to=''>
                                    <p>{t('privacy')}</p>
                                </Link>
                            </div>
                            <div className='text-xs  mt-5 hidden lg:block text-center'>
                                <p>{t('all_rights_reserved')}</p>
                            </div>
                        </div>
                    </div>
                    <div className='lg:col-span-3 my-auto'>
                        <div className='bg-dark grid grid-cols-3 gap-3 justify-items-center h-full content-center xl:px-20 sm:px-10 px-0'>
                            <Link to=''>
                                <InstagramIcon sx={{ fontSize: '25px' }} className='text-main' />
                            </Link>
                            <Link to=''>
                                <WhatsAppIcon sx={{ fontSize: '25px' }} className='text-main' />
                            </Link>
                            <Link to=''>
                                <TwitterIcon sx={{ fontSize: '25px' }} className='text-main' />
                            </Link>
                            
                        </div>
                        <div className='text-xs text-white mt-5 lg:hidden text-center'>
                                <p>{t('all_rights_reserved')}</p>
                            </div>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default FooterComponent