import React from 'react'
import { useTranslation } from 'react-i18next';
import StaticInputField from '../../../Shared/StaticInputField';
function BankInfo({data}) {
    const { t } = useTranslation();
    return (
        <div className="flex flex-col gap-12">
            <div className='bg-second-card border-white border-2 rounded-xl py-8 md:px-11 px-5 text-white'>
                <div className='flex flex-col gap-5'>
                    <h2 className='font-semibold leading-5'>{t('bank_information')}</h2>
                    <div className='flex flex-col gap-[10px]'>
                        <StaticInputField value={data?.bankAccount?.bankTitle} label={t('input_bank_name')} />
                        <StaticInputField value={data?.bankAccount?.username} label={t('input_bank_account_name')} />
                        <StaticInputField value={data?.bankAccount?.IBAN} label={t('input_account_number')} />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default BankInfo