import React, { useEffect } from 'react'
import {  useFormik } from 'formik'
import { CircularProgress } from '@mui/material';
import * as Yup from 'yup'
import { useTranslation } from 'react-i18next';
import InputField from '../../Shared/InputField';
import { useMe } from '../../../data/user';
import { useCustomMutation } from '../../../hooks/use-custome-mutation';
import client from '../../../client';
import { useCities, useGovernorates } from '../../../data/vistor';

const validationSchema = Yup.object({
    password: Yup.string(),
    name: Yup.string().required('name is required'),
    governorate: Yup.string().required('governorate is required'),
    city: Yup.string().required('city is required'),

});

function MainInformation() {
    const { me } = useMe()
    const { t } = useTranslation();
    const [error, setError] = React.useState('');
    const [success, setSuccess] = React.useState('');
    const [cities, setCities] = React.useState([]);
    const [governorate, setGovernorate] = React.useState([]);

    const { data: APIGovernorate } = useGovernorates();

    const [selectedGovernorate, setSelectedGovernorate] = React.useState(me?.governorate);
    const { data: APICities } = useCities(selectedGovernorate?.label || me?.governorate);


    const findIndexWithLabel = (value, arr) => {
        if (!value)
            return 0;

        let index = arr.findIndex(x => x.label === value);
        if (index === -1)
            return 0;

        return index
    }
    const getGovernorateOrCities = (list) => {
        if (!list) return [];
        return list.map((element, idx) => ({ label: element, value: idx }));
    };
    useEffect(() => {
        const newGovernorate = getGovernorateOrCities(APIGovernorate);
        const newCities = getGovernorateOrCities(APICities);

        if (JSON.stringify(newGovernorate) !== JSON.stringify(governorate)) {
            setGovernorate(newGovernorate);
        }

        if (JSON.stringify(newCities) !== JSON.stringify(cities)) {
            setCities(newCities);
        }
    }, [APIGovernorate, APICities, governorate, cities]);

    const ProfileMutation = useCustomMutation({
        mutationFn: client.auth.postProfile,
        onSuccess: (data) => {
            setSuccess('تم التحديث بنجاح')
            setTimeout(() => {
                setSuccess('')
            }, 5000)
        },
        onError: (error) => {
            setError('حدث خطأ ما, الرجاء المحاولة لاحقاً')
            setTimeout(() => {
                setError('')
            }, 5000)
        },
    });

    let PersonalInfoFormik = useFormik({
        enableReinitialize: true,
        initialValues: {
            password: me?.password,
            name: me?.name,
            governorate: findIndexWithLabel(me?.governorate, governorate),
            city: findIndexWithLabel(me?.city, cities),
        },
        validationSchema,
        onSubmit: (values) => {
            const formData = new FormData();
            if (values.password) {
                formData.append('password', values.password);
            }
            if (values.name !== me?.name) {
                formData.append('name', values.name);
            }
            if (me?.governorate !== governorate[values.governorate].label) {
                formData.append('governorate', governorate[values.governorate].label);
            }
            if (me?.city !== cities[values.city].label) {
                formData.append('city', cities[values.city].label);
            }
            ProfileMutation.mutate(formData);
        }
    })

    const handleOnChange = (event) => {
        if (event.target.id === 'governorate') {
            let idx = event.nativeEvent.target.value;
            let value = governorate[idx];
            setSelectedGovernorate(value);
        }
    }

    return (
        <form onSubmit={PersonalInfoFormik.handleSubmit} onChange={handleOnChange}>
            <div className="flex flex-col gap-12">
                <div className='bg-second-card  rounded-xl py-8 md:px-11 px-5 text-white'>
                    <div className='flex flex-col gap-5'>
                        <h2 className='font-semibold leading-5'>{t('main_information')}</h2>
                        <div className='flex flex-col gap-[10px]'>

                            <div className='grid grid-cols-1 gap-4'>
                                <InputField
                                    name={'name'}
                                    label={t('input_name')}
                                    onBlur={PersonalInfoFormik.handleBlur}
                                    onChange={PersonalInfoFormik.handleChange}
                                    value={PersonalInfoFormik.values.name}
                                    IsError={PersonalInfoFormik.errors.name && PersonalInfoFormik.touched.name}
                                    error={PersonalInfoFormik.errors.name} />
                            </div>
                            <div className='grid grid-cols-1 gap-4'>
                                <InputField
                                    name='governorate'
                                    as='select'
                                    value={PersonalInfoFormik.values.governorate}
                                    onBlur={PersonalInfoFormik.handleBlur}
                                    onChange={PersonalInfoFormik.handleChange}
                                    IsError={PersonalInfoFormik.errors.governorate && PersonalInfoFormik.touched.governorate}
                                    error={PersonalInfoFormik.errors.governorate}
                                    label={t('input_governorate')}
                                    children={governorate.map((item, index) => <option key={index} value={item.value} label={item.label}>{item.label}</option>)}
                                />
                            </div>
                            <div className='grid grid-cols-1 gap-4'>
                                <InputField
                                    name='city'
                                    as='select'
                                    value={PersonalInfoFormik.values.city}
                                    onBlur={PersonalInfoFormik.handleBlur}
                                    onChange={PersonalInfoFormik.handleChange}
                                    IsError={PersonalInfoFormik.errors.city && PersonalInfoFormik.touched.city}
                                    error={PersonalInfoFormik.errors.city}
                                    label={t('input_city')}
                                    children={cities.map((item, index) => <option key={index} value={item.value} label={item.label}>{item.label}</option>)}
                                />
                            </div>
                            <div className='grid grid-cols-12 items-center gap-4'>
                                <div className='col-span-10'>
                                    <InputField
                                        name='password'
                                        type='password'
                                        label={t('input_password')}
                                        onBlur={PersonalInfoFormik.handleBlur}
                                        onChange={PersonalInfoFormik.handleChange}
                                        value={PersonalInfoFormik.values.password}
                                        IsError={PersonalInfoFormik.errors.password && PersonalInfoFormik.touched.password}
                                        error={PersonalInfoFormik.errors.password}
                                    />
                                </div>
                                <div className='col-span-2'>

                                    <button className='py-4 px-6 bg-main rounded-2xl mt-7 w-full text-second font-semibold' type='submit'>
                                        {ProfileMutation.isPending ? <CircularProgress style={{
                                            width: '20px',
                                            height: '20px',
                                            color: '#fff',
                                        }} /> : <p className='font-bold'> {t('change')} </p>}
                                    </button>
                                </div>
                            </div>
                            {error && <div className='text-red-600 text-center'>{error}</div>}
                            {success && <div className='text-green-600 text-center'>{success}</div>}

                        </div>
                    </div>
                </div>

            </div>
        </form>
    )
}

export default MainInformation