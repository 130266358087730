import { useState, useEffect } from 'react';

export function useCounterDown(seconds) {
    const [timeLeft, setTimeLeft] = useState(seconds);

    useEffect(() => {
        if (timeLeft > 0) {
            const timerId = setTimeout(() => {
                setTimeLeft(timeLeft - 1);
            }, 1000);

            return () => clearTimeout(timerId); // Cleanup the timer on component unmount
        }
    }, [timeLeft]);

    useEffect(() => {
        // If the `seconds` changes, reset the timer
        setTimeLeft(seconds);
    }, [seconds]);

    return timeLeft;
}
