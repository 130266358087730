import React from 'react'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { Link, useNavigate } from 'react-router-dom';
import { API_ENDPOINTS } from '../../../client/endpoints';
import { useAuth } from '../../../Components/Auth/context';
import client from '../../../client';
import { CircularProgress } from '@mui/material';
import { useCustomMutation } from '../../../hooks/use-custome-mutation';
import { useTranslation } from 'react-i18next';
import { User} from '../../../Icons/Icons';
import Input from '../../../Components/Auth/Forms/Input';
import AuthRoute from '../../../Components/AuthRoute';

export default function Login() {
  const { t } = useTranslation();
  const [error, setError] = React.useState('')
  let navigate = useNavigate()
  const { authorize , authType , setAuthType } = useAuth();
  let validationSchema = Yup.object({
    user:Yup.string().required(),
    password:Yup.string().required(),
  })
  const loginMutation = useCustomMutation({
    mutationFn: client.auth.login,
    queryToInvalidate: {
      queryKey: [API_ENDPOINTS.GET_PROFILE],
    },
    onSuccess: (data) => {
      if (data.status) {
        authorize(data.data);
        navigate('/');
      }
    },
    onError: (data) => {
      setError(data.message)
      if(data.code===405){
        setTimeout(() => {
          navigate(`/auth/otp?user=${loginFormik.values.user}`)
        },1000)
      }
      setTimeout(() => {
        setError('')
      }, 5000)
    },
  });

  let loginFormik = useFormik({
    initialValues:{
      user:'',
      password:'',
    },
    validationSchema,
    onSubmit:(values)=>{
      // navigate('/')
      values.type = authType
      loginMutation.mutate(values)
    }
  })

  return (
    <AuthRoute>
    <div className='flex flex-col'>
  <form onSubmit={loginFormik.handleSubmit} className='flex flex-col gap-y-8 lg:p-8 lg:w-[480px]'>
      <h1 className='font-semibold text-[28px] text-white text-center leading-[48px] tracking-tight'>{t('login_auth_message')}</h1>
      <Input 
        onBlur={loginFormik.handleBlur} 
        onChange={loginFormik.handleChange}
        value={loginFormik.values.user}
        name={'user'}
        label={t('input_email')}
        rightIcon={<User/>}
        placeholder={t('placeholder_email')}
        IsError={loginFormik.errors.user && loginFormik.touched.user} 
        error={loginFormik.errors.user}  
      />
      <Input
        onBlur={loginFormik.handleBlur}
        onChange={loginFormik.handleChange}
        value={loginFormik.values.password}
        name={'password'}
        type='password'
        label={t('input_password')}
        placeholder={t('placeholder_password')}
          IsError={loginFormik.errors.password && loginFormik.touched.password} 
          error={loginFormik.errors.password}  
      />
      
      <div className='flex justify-between items-baseline'>
        <div className='text-white font-semibold flex items-center gap-2'><input type="checkbox" />{t('remember_me')}</div>
        <Link to='/auth/recovery' className='text-main font-semibold'>{t('forget_password')}</Link>
      </div>
      <div>
         {error && <div className='text-red-500 text-center'>{error}</div>}
         <button type='submit' disabled={!(loginFormik.isValid && loginFormik.dirty ) || loginMutation.isPending} className='bg-main font-semibold text-second w-full px-2 py-3 rounded-xl'>
           {loginMutation.isPending?<CircularProgress style={{
             width: '20px',
             height: '20px',
             color: '#fff',
           }} />:t('continue')}</button>
      </div>
  </form>
      <div>
        <p className='font-semibold flex justify-center gap-2 items-center text-muted'>{t(`haven't_account`)} <Link to='/auth/signup' className='text-main'>{t('sign_here')}</Link></p>
        <p className='font-semibold flex justify-center gap-2 items-center text-muted'>{t(`register`)}<span onClick={()=> setAuthType(authType==='lawyer'?'client':'lawyer')} className='text-main cursor-pointer'>{t('_as')}{authType === 'lawyer'?t('client'):t('lawyer')}</span></p>
      </div>
    </div>
    </AuthRoute>

  )
}
