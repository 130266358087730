import React from 'react'
import * as Yup from 'yup'
import { useTranslation } from 'react-i18next';
import InputField from '../../Shared/InputField';
import { useMe } from '../../../data/user';
import { useFormik } from 'formik';
import { CircularProgress } from '@mui/material';
import { useCustomMutation } from '../../../hooks/use-custome-mutation';
import client from '../../../client';



const validationSchema = Yup.object({
    id_number: Yup.string().required('id_number is required'),
});
function CallInformation({ props }) {
    const { me } = useMe()
    const { t } = useTranslation();
    const [error, setError] = React.useState('');
    const [success, setSuccess] = React.useState('');

    const ProfileMutation = useCustomMutation({
        mutationFn: client.auth.postProfile,
        onSuccess: (data) => {
            setSuccess('تم التحديث بنجاح')
            setTimeout(() => {
                setSuccess('')
            }, 5000)
        },
        onError: (error) => {
            setError('حدث خطأ ما, الرجاء المحاولة لاحقاً')
            setTimeout(() => {
                setError('')
            }, 5000)
        },
    });

    let PersonalInfoFormik = useFormik({
        enableReinitialize: true,
        initialValues: {
            email: me?.email,
            id_number: me?.id_number,
            phone: me?.phoneNumber,
        },
        validationSchema,
        onSubmit: (values) => {

            const formData = new FormData();
            formData.append('id_number', values.name);

            ProfileMutation.mutate(formData);
        }
    })
    return (
        <div className="flex flex-col gap-12">
            <div className='bg-second-card  rounded-xl py-8 md:px-11 px-5 text-white'>
                <div className='flex flex-col gap-5'>
                    <h2 className='font-semibold leading-5'>{t('call_information')}</h2>
                    <div className='flex flex-col gap-[10px]'>

                        <div className='grid grid-cols-1 gap-4'>

                            <InputField
                                disabled={true}
                                name='id_number'
                                type='id_number'
                                label={t('id_number')}
                                onBlur={PersonalInfoFormik.handleBlur}
                                onChange={PersonalInfoFormik.handleChange}
                                value={PersonalInfoFormik.values.id_number}
                                IsError={PersonalInfoFormik.errors.id_number && PersonalInfoFormik.touched.id_number}
                                error={PersonalInfoFormik.errors.id_number}
                            />
                        </div>
                        <div className='grid grid-cols-1 gap-4'>
                            <InputField
                                name='phone'
                                label={t('input_phone')}
                                disabled={true}
                                onBlur={PersonalInfoFormik.handleBlur}
                                onChange={PersonalInfoFormik.handleChange}
                                value={PersonalInfoFormik.values.phone}
                                IsError={PersonalInfoFormik.errors.phone && PersonalInfoFormik.touched.phone}
                                error={PersonalInfoFormik.errors.phone}
                            />                        
                        </div>
                        <div className='grid grid-cols-1 items-center gap-4'>
                                <InputField
                                    name='email'
                                    type='email'
                                    disabled={true}
                                    label={t('input_email')}
                                    onBlur={PersonalInfoFormik.handleBlur}
                                    onChange={PersonalInfoFormik.handleChange}
                                    value={PersonalInfoFormik.values.email}
                                    IsError={PersonalInfoFormik.errors.email && PersonalInfoFormik.touched.email}
                                    error={PersonalInfoFormik.errors.email}
                                />                            
                            <div className='col-span-2'>

                                <button className='py-4 px-6 bg-main rounded-2xl mt-7 w-full text-second font-semibold'>

                                    {ProfileMutation.isPending ? <CircularProgress style={{
                                        width: '20px',
                                        height: '20px',
                                        color: '#fff',
                                    }} /> : <p className='font-bold'> {t('change')} </p>}
                                </button>
                            </div>
                        </div>
                        {error && <div className='text-red-600 text-center'>{error}</div>}
                    {success && <div className='text-green-600 text-center'>{success}</div>}
                    </div>
                </div>
            </div>

        </div>
    )
}

export default CallInformation