import React from 'react'
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { API_ENDPOINTS } from '../../../client/endpoints';
import client from '../../../client';
import { useCustomMutation } from '../../../hooks/use-custome-mutation';
import { Delete } from '@mui/icons-material';


function DayTime({item,workTimeId}) {
    
    const deleteMutation = useCustomMutation({
        mutationFn: client.WorkTime.delete_period,
        queryToInvalidate: {
            queryKey: [API_ENDPOINTS.WORK_TIME],
        },
      });
    
    
    return (
        <div className='flex items-center justify-between'>
            <div className='flex gap-2'>
                <AccessTimeIcon />
                <div className='flex gap-1'>
                    <p>{item.fromTime}</p>
                    <p>الى</p>
                    <p>{item.toTime}</p>
                </div>
            </div>
            <Delete onClick={()=> deleteMutation.mutate({workTimeId,periodId:item._id})} className='cursor-pointer text-red-600'/>
        </div>
    )
}

export default DayTime