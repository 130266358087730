import React from 'react'
import PlanItem from './PlanItem';
import { useTranslation } from 'react-i18next';
import { CircularProgress } from '@mui/material';
import { useCustomMutation } from '../../../../hooks/use-custome-mutation';
import client from '../../../../client';
import { API_ENDPOINTS } from '../../../../client/endpoints';
import { useNavigate } from 'react-router-dom';

function Plan({plan}) {
    const [error, setError] = React.useState(null);
    const navigate = useNavigate()
    const MutationSubscript = useCustomMutation({
        mutationFn: client.Plans.subscript,
        queryToInvalidate: {
          queryKey: [API_ENDPOINTS.SUBSCRIPT_PLAN(plan._id)],
        },
        onSuccess: (data) => {
              navigate('/');
        },
        onError: (data) => {
          setError(data.message || data.errors[0]?.message);
          setTimeout(() => setError(''), 5000);
        },
      });
    
    const {t} = useTranslation();
    return (
        <div className={` ${ plan.isPremium? 'text-third bg-gradient-to-b from-[#FBE67B] to-[#D4A041] rounded-xl shadow-[0_10px_25px_0px_rgba(204,217,255,0.55)]': 'text-white-50 bg-[#05020D]/80 rounded-md'} p-10 text-center w-full`}>
            <h3 className='text-xl font-bold p-5'>{plan.title}</h3>
            <div className='flex items-center gap-1 text-center '>
                <h3 className='2xl:text-6xl xl:text-4xl text-4xl font-medium font-manrope'>KWD {plan.price}</h3>
                <p>/ {plan.subTitle}</p>
            </div>
            {error && <div className="text-red-500 text-center">{error}</div>}
            <div className='pt-6 pb-10'>
                <button onClick={()=>MutationSubscript.mutate(plan._id)} className={`bg-transparent w-full rounded-md p-3 font-bold ${plan.isPremium? 'bg-main text-dark': 'border-main text-main'}`}>
                    {MutationSubscript.isPending ? (
              <CircularProgress
                style={{
                  width: '20px',
                  height: '20px',
                  color: '#fff',
                }}
              />
            ) : (
                <span className=''>{t('start_now')}</span>
            )}
                </button>
            </div>
            <div className='flex flex-col gap-3'>
                {plan.features.map((item, index) => {
                    return (
                        <PlanItem title={item} isMiddle={plan.isPremium} key={index} />
                    );
                })}
            </div>
        </div>
    )
}

export default Plan