import React from 'react'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../../../Components/Auth/context';
import client from '../../../client';
import { CircularProgress } from '@mui/material';
import { useCustomMutation } from '../../../hooks/use-custome-mutation';
import { useTranslation } from 'react-i18next';
import { User , SMS, Call, RegistrationNumber} from '../../../Icons/Icons';
import Input from '../../../Components/Auth/Forms/Input';
import AuthRoute from '../../../Components/AuthRoute';

export default function Signup() {
  const { t } = useTranslation();
  const [error, setError] = React.useState('')
  let navigate = useNavigate()
  const { authorize , authType , setAuthType } = useAuth();
  const validationSchema = Yup.object({
    name: Yup.string()
    .required('الاسم مطلوب')
    .matches(/^(?:\S+\s+){2,}\S+$/, 'يجب أن يتكون الاسم من ثلاث كلمات على الأقل'),
        email: Yup.string()
      .email('البريد الإلكتروني غير صالح')
      .required('البريد الإلكتروني مطلوب'),
    phoneNumber: Yup.string()
    .required('رقم الهاتف مطلوب'),
    password:Yup.string().required('كلمة المرور مطلوبة'),
    confirmPassword: Yup.string()
      .required('تأكيد كلمة المرور مطلوب')
      .oneOf([Yup.ref('password'), null], 'يجب أن تتطابق كلمات المرور'),
  });
    const ClientMutation = useCustomMutation({
    mutationFn: client.auth.signUpClient,
    // queryToInvalidate: {
    //   queryKey: [API_ENDPOINTS.GET_PROFILE],
    // },
    onSuccess: (data) => {
      if (data.status) {
        authorize(data.data);
        navigate(`/auth/otp?user=${Formik.values.email}`);
      }
    },
    onError: (error) => {
      setError(error.message)
      setTimeout(() => {
        setError('')
      }, 5000)
    },
  });
  const LawyerMutation = useCustomMutation({
    mutationFn: client.auth.signUpLawyer,
    // queryToInvalidate: {
    //   queryKey: [API_ENDPOINTS.GET_PROFILE],
    // },
    onSuccess: (data) => {
      if (data.status) {
        authorize(data.data);
        navigate(`/auth/otp?user=${Formik.values.email}`);
      }
    },
    onError: (error) => {
      setError(error.message)
      setTimeout(() => {
        setError('')
      }, 5000)
    },
  });

  let Formik = useFormik({
    initialValues:{
      name:'',
      email:'',
      phoneNumber:'',
      registrationNumber:'',
      frontID:null,
      backID:null,
      password:'',
      confirmPassword:''
    },
    validationSchema,
    onSubmit:(values)=>{
      // navigate('/')
      if(authType==='client'){
        const data = {
          name: values.name,
          email: values.email,
          phoneNumber: values.phoneNumber,
          password: values.password
        }
        ClientMutation.mutate(data)
      }else{
        if(!values.frontID){
          return setError('الصورة الامامية مطلوبة')
        }
        if(!values.backID){
          return setError('الصورة الخلفية مطلوبة')
        }
        if(!values.registrationNumber){
          return setError('رقم القيد مطلوب')
        }
        const formData = new FormData();
        formData.append('name', values.name);
        formData.append('email', values.email);
        formData.append('phoneNumber', values.phoneNumber);
        formData.append('registrationNumber', values.registrationNumber);
        formData.append('frontID', values.frontID);
        formData.append('backID', values.backID);
        formData.append('password', values.password);

        LawyerMutation.mutate(formData)
      }
    }
  })
  return (
    <AuthRoute>
    <form onSubmit={Formik.handleSubmit} className='flex flex-col gap-y-7 lg:p-8 lg:w-[480px]'>
        <div className='flex flex-col gap-3 text-white text-center tracking-tight'>
            <div className="font-semibold text-[32px] leading-[48px]">
                {t('signup_auth_message')}
            </div> 
            <div className='text-muted text-sm'>
                {t('please_your_data')}
            </div>
        </div>
        <div className='flex flex-col gap-y-7'>
            <Input 
              onBlur={Formik.handleBlur} 
              onChange={Formik.handleChange}
              value={Formik.values.name}
              name={'name'}
              label={t('input_full_name')}
              rightIcon={<User/>}
              placeholder={t('placeholder_full_name')}
              IsError={Formik.errors.name && Formik.touched.name} 
              error={Formik.errors.name}  
            />
            <Input 
              onBlur={Formik.handleBlur} 
              onChange={Formik.handleChange}
              value={Formik.values.phoneNumber}
              name={'phoneNumber'}
              type={'text'}
              label={t('input_phone')}
              rightIcon={<Call/>}
              // leftIcon={<span className='flex flex-row-reverse gap-1 items-center '><ArrowDown color='#667085'/> KW</span>}
              placeholder={t('placeholder_phone')}
              IsError={Formik.errors.phoneNumber && Formik.touched.phoneNumber} 
              error={Formik.errors.phoneNumber}  
            />
            <Input 
              onBlur={Formik.handleBlur} 
              onChange={Formik.handleChange}
              value={Formik.values.email}
              name={'email'}
              label={t('input_email')}
              rightIcon={<SMS/>}
              placeholder={t('placeholder_email')}
              IsError={Formik.errors.email && Formik.touched.email} 
              error={Formik.errors.email}  
            />
            {authType === 'lawyer' && 
            <>
            <Input 
              onBlur={Formik.handleBlur} 
              onChange={Formik.handleChange}
              value={Formik.values.registrationNumber}
              name={'registrationNumber'}
              type={'number'}
              label={t('input_registration_number')}
              rightIcon={<RegistrationNumber/>}
              placeholder={t('placeholder_registration_number')}
              IsError={Formik.errors.registrationNumber && Formik.touched.registrationNumber} 
              error={Formik.errors.registrationNumber}  
            />
            <Input 
              onBlur={Formik.handleBlur} 
              onChange={(event) => Formik.setFieldValue('frontID', event.currentTarget.files[0])}
              value={Formik.values.frontID}
              name={'frontID'}
              type="file"
              isFileLabel={true}
              label={t('input_front_id')}
              IsError={Formik.errors.frontID && Formik.touched.frontID} 
              error={Formik.errors.frontID}  
            />
            <Input 
              onBlur={Formik.handleBlur} 
              onChange={(event) => Formik.setFieldValue('backID', event.currentTarget.files[0])}
              value={Formik.values.backID}
              name={'backID'}
              type="file"
              isFileLabel={false}
              label={t('input_back_id')}
              IsError={Formik.errors.backID && Formik.touched.backID} 
              error={Formik.errors.backID}
            />
            </>
            }
            <Input 
              onBlur={Formik.handleBlur} 
              onChange={Formik.handleChange}
              value={Formik.values.password}
              name={'password'}
              type={'password'}
              
              label={t('input_password')}
              placeholder={t('placeholder_password')}
              IsError={Formik.errors.password && Formik.touched.password} 
              error={Formik.errors.password}  
            />
            <Input 
              onBlur={Formik.handleBlur} 
              onChange={Formik.handleChange}
              value={Formik.values.confirmPassword}
              name={'confirmPassword'}
              type={'password'}
              label={t('input_confirm_password')}
              placeholder={t('placeholder_password')}
              IsError={Formik.errors.confirmPassword && Formik.touched.confirmPassword} 
              error={Formik.errors.confirmPassword}
            />
        </div>
        {error && <div className='text-red-500 text-center'>{error}</div>}
        <div>
            <button type='submit' disabled={!(Formik.isValid && Formik.dirty ) || ClientMutation.isPending || LawyerMutation.isPending} className='bg-main font-semibold text-second w-full px-2 py-3 rounded-xl'>
            {ClientMutation.isPending || LawyerMutation.isPending?<CircularProgress style={{
                width: '20px',
                height: '20px',
                color: '#fff',
            }} />:t('continue')}</button>
        </div>
        <div>
          <p className='font-semibold flex justify-center gap-2 items-center text-muted'>{t(`have_account`)} <Link to='/auth/login' className='text-main'>{t('sign_in')}</Link></p>
          <p className='font-semibold flex justify-center gap-2 items-center text-muted'>{t(`register`)}<span onClick={()=> setAuthType(authType==='lawyer'?'client':'lawyer')} className='text-main cursor-pointer'>{t('_as')}{authType === 'lawyer'?t('client'):t('lawyer')}</span></p>
        </div>
    </form>
    </AuthRoute>
  )
}
