import React from 'react'
import WalletForm from '../../../Components/Lawyer/Wallet/WalletForm/WalletForm'
import BankInfo from '../../../Components/Lawyer/Wallet/BankInfo/BankInfo'
import EarningDetails from '../../../Components/Lawyer/Wallet/EarningDetails/EarningDetails'
import { useWallet } from '../../../data/vistor';

function Wallet() {
  const {data} = useWallet();
  return (
    <div className="pt-10 pb-12 container mx-auto">

    <div className='flex flex-col gap-9'>
        {/* Wallet Form */}
        <WalletForm data={data}/>
        <BankInfo data={data}/>
        <EarningDetails/>
    {/* <PersonalInfoFrom /> */}
    </div>
    {/* main information */}
</div>
  )
}

export default Wallet