import React from 'react'
import LawyerCard from './LawyerCard'
import Slider from 'react-slick'
import './Lawyers.css'
import { NextArrow, PrevArrow } from './arrows'
import { useTranslation } from 'react-i18next'
import { useUsers } from '../../../data/vistor'
import { Link } from 'react-router-dom'



const min = (a, b) => a < b ? a : b
function getSettings(minCount, isInfinite = true, isDots = false) {
    return {
        dots: isDots,
        infinite: isInfinite,
        speed: 500,
        slidesToShow: min(minCount, 4),
        slidesToScroll: 3,
        initialSlide: 0,
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />,
        responsive: [
            {
                breakpoint: 1280,
                settings: {
                    slidesToShow: min(minCount, 3),
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: min(minCount, 2),
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: min(minCount, 1),
                    slidesToScroll: 1,
                }
            },
        ]
    }
}
function Lawyers() {
    const { t } = useTranslation();
    const { data: fetchedUsers = {} } = useUsers({
        page: 1,
        limit: 100,
        type: 'lawyer',
    });

    if (fetchedUsers.data != null && fetchedUsers.data.length !== 0)
        return (<div className='bg-second py-20'>
            <div className='container mx-auto slider-container'>
                <div className='flex flex-col gap-24'>
                    <Link to={'/lawyers'} className='text-4xl font-semibold text-white text-right'>{t('our_lawyers')}</Link>
                    <Slider className='gap-8' {...getSettings(fetchedUsers?.data.length)}>
                        {fetchedUsers.data.map((lawyer, index) => (
                        <div className='mx-8'>
                            <LawyerCard key={index} lawyer={lawyer} />
                        </div>
                        ))
                        }
                    </Slider>
                </div>
            </div>
        </div>)
    else
        return (<></>)
}

export default Lawyers