import axios from 'axios';
import { APIError } from './error';
// import cookies  from 'js-cookie';
import { getAuthToken } from './token-utils';

export const Axios = axios.create({
  baseURL:process.env.REACT_APP_API_URL,
});

Axios.interceptors.request.use((config) => {
  // const lang = cookies.get('locale')
  const token = getAuthToken()
  config.headers.lang = 'ar';
  if(token && token !=='undefined'){
    config.headers.Authorization = `Bearer ${token}`
  }
  return config;
});

export class HttpClient {
  static async get(url, params) {
    try {
      const response = await Axios.get(url, params);
      return response.data;
    } catch (error) {
      throw new APIError(error?.response);
    }
  }

  static async post(
    url,
    data,
    config,
  ) {
    try {
      const response = await Axios.post(url, data, config);                          
      return response.data;
    } catch (error) {     
      if(error.message &&  error.message ==='Network Error'){
        throw new APIError({data:{
          message:'Internal Server Error'
        }
        })
      }else{
        throw new APIError(error?.response);
      }
    }
  }

  static async put(url, data) {
    try {
      const response = await Axios.put(url, data);
      return response.data;
    } catch (error) {
      throw new APIError(error?.response);
    }
  }

  static async patch(
    url,
    data,
    config,
  ) {
    try {
      const response = await Axios.patch(url, data, config);
      return response.data;
    } catch (error) {
      throw new APIError(error?.response);
    }
  }

  static async delete(url, config) {
    try {
      const response = await Axios.delete(url, config);
      return response.data;
    } catch (error) {
      throw new APIError(error?.response);
    }
  }
}
