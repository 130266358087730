import React from 'react'

export default function ServiceCard({ title, description, icon }) {
    return (
        <div className='border-4 bg-second border-main rounded-xl py-12 px-3 my-3 sm:mx-0 mx-10 text-center text-white'>
            <img src={icon}  className='mx-auto' alt=''/>
            <h3 className='text-2xl font-medium my-10'>{title}</h3>
            <div className=' mx-auto'>
                <p className='text-[16px]'>{description}</p>
            </div>
        </div>
    )
}
