import { useQuery } from '@tanstack/react-query';
import client from '../client';
import { API_ENDPOINTS } from '../client/endpoints';
import { useMe } from './user';
export const useUsers = (data) => {
  return useQuery({
    queryKey: [API_ENDPOINTS.GET_ALL_USERS, data],
    queryFn: () => client.Users.get_all(data),
    keepPreviousData: true,
  });
};
export const useConsultants = (data) => {
  return useQuery({
    queryKey: [API_ENDPOINTS.GET_ALL_CONSULTANTS, data],
    queryFn: () => client.Consultants.get_all(data),
    keepPreviousData: true,
  });
};
export const useSubscript= (planId) => {
  return useQuery({
    queryKey: [API_ENDPOINTS.SUBSCRIPT_PLAN],
    queryFn: () => planId ? client.Plans.subscript(planId) : Promise.resolve(null),
    keepPreviousData: true,
    enabled: !!planId,
  });
};
export const useUser = (id) => {
  const { isPending, data, error, isError } = useQuery({
    queryKey: [API_ENDPOINTS.GET_USER(id)],
    queryFn: ()=> client.Users.get(id),
  });    
  return {
    isPending,
    isError,
    error,
    data: data?.data ?? [],
  };
};
export const useConsultant = (id) => {
  const { isPending, data, error, isError } = useQuery({
    queryKey: [API_ENDPOINTS.GET_CONSULTATION(id)],
    queryFn: ()=> client.Consultants.get(id),
  });    
  return {
    isPending,
    isError,
    error,
    data: data?.data ?? [],
  };
};
export const usePlans = () => {
  const { isPending, data, error, isError } = useQuery({
    queryKey: [API_ENDPOINTS.GET_ALL_PLANS],
    queryFn: client.Plans.get_all,
  });    

  return {
    isPending,
    isError,
    error,
    data: data?.data??[],
  };
};
export const useAdvertisement = () => {
  const { isPending, data, error, isError } = useQuery({
    queryKey: [API_ENDPOINTS.GET_ALL_ADVERTISEMENTS],
    queryFn: client.Advertisement.get_all,
  });    

  return {
    isPending,
    isError,
    error,
    data: data?.data??[],
  };
};
export const useTermsConditions = () => {
  const { isPending, data, error, isError } = useQuery({
    queryKey: [API_ENDPOINTS.GET_ALL_TERMS],
    queryFn: client.TermsConditions.get_all,
  });
  return {
    isPending,
    isError,
    error,
    data: data?.data??[],
  };
};
export const useAboutApp = () => {
  const { isPending, data, error, isError } = useQuery({
    queryKey: [API_ENDPOINTS.GET_ALL_ABOUT_APP],
    queryFn: client.AboutApp.get_all,
  });
  return {
    isPending,
    isError,
    error,
    data: data?.data??[],
  };
}

export const useNotifications = (item) => {
  const { isPending, data, error, isError } = useQuery({
    queryKey: [API_ENDPOINTS.GET_ALL_NOTIFICATIONS,item],
    queryFn: ()=> client.Notifications.get_all(item),
  });
  return {
    isPending,
    isError,
    error,
    data,
  };
}
export const useGovernorates = () => {
  const { isPending, data, error, isError } = useQuery({
    queryKey: [API_ENDPOINTS.GET_CITIES],
    queryFn: client.StaticData.get_Governorates,
  });
  return {
    isPending,
    isError,
    error,
    data: data?.data??[],
  };
}
export const useCities = (name) => {
  const { isPending, data, error, isError } = useQuery({
    queryKey: [API_ENDPOINTS.GET_CITIES,name],
    queryFn: () => client.StaticData.get_cities(name),
  });
  return {
    isPending,
    isError,
    error,
    data: data?.data??[],
  };
}
export const useSpecialties = () => {
  const { isPending, data, error, isError } = useQuery({
    queryKey: [API_ENDPOINTS.GET_SPECIALTIES],
    queryFn: client.StaticData.get_specialties,
  })
  return {
    isPending,
    isError,
    error,
    data: data?.data??[],
  };
}
export const useConsultations = () => {
  const { isPending, data, error, isError } = useQuery({
    queryKey: [API_ENDPOINTS.CREATE_CONSULTANTS],
    queryFn: client.Consultants.get_all,
  })
  return {
    isPending,
    isError,
    error,
    data: data?.data??[],
  };
}

export const useConsultation = (id) => {
  const { isPending, data, error, isError } = useQuery({
    queryKey: [API_ENDPOINTS.GET_CONSULTATION(id)],
    queryFn: client.Consultants.get(id),
  })
  return {
    isPending,
    isError,
    error,
    data: data?.data??[],
  };
}
export const useWallet = () => {
  const { isPending, data, error, isError } = useQuery({
    queryKey: [API_ENDPOINTS.GET_WALLET],
    queryFn: client.Wallet.get_wallet,
  })
  return {
    isPending,
    isError,
    error,
    data: data?.data??[],
  };
}

export const useTransactions = () => {
  const { isPending, data, error, isError } = useQuery({
    queryKey: [API_ENDPOINTS.TRANSACTION],
    queryFn: client.Transactions.get_all,
  })
  return {
    isPending,
    isError,
    error,
    data: data?.data??[],
  };
}
export const useTransitions = () => {
  const { isPending, data, error, isError } = useQuery({
    queryKey: [API_ENDPOINTS.TRANSITIONS],
    queryFn: client.Transitions.get_all,
  })
  return {
    isPending,
    isError,
    error,
    data: data?.data??[],
  };
}

export const useWorkTimes = () => {
  const {me} = useMe()
  const { isPending, data, error, isError } = useQuery({
    queryKey: [API_ENDPOINTS.WORK_TIME],
    queryFn: client.WorkTime.get_work_times,
    enabled: me?.type ==='lawyer'
  })
  return {
    isPending,
    isError,
    error,
    data: data?.data??[],
  };
}

export const usePeriodsByDate = (item) => {
  return useQuery({
    queryKey: [API_ENDPOINTS.GET_PERIODS_BY_DATE(item.id), item.id, item.date],
    queryFn: () => item ? client.WorkTime.get_periods_by_date(item) : Promise.resolve(null),
    keepPreviousData: true,
    enabled: !!item.date,
  });
};

