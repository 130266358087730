import React from 'react'
import DayButton from './DayButton';
import { useTranslation } from 'react-i18next';
import { useWorkTimes } from '../../../data/vistor';
import AddWorkTime from './AddWorkTime';
import WorkTime from './WorkTime';

export default function WorkTimes() {
    const [day, setDay] = React.useState('saturday');
  
    const { t } = useTranslation();
    const {data } = useWorkTimes()
  return (
                    <div className='flex flex-col gap-5 bg-second-card border-white border-2 rounded-xl py-8 md:px-11 px-5 text-white'>
                        {/* Header */}
                        <div className='flex flex-row justify-between'>
                            <div>
                                <h2 className='pb-3 font-semibold leading-5'>{t('working_time')}</h2>
                                <p className=' font-normal text-[10px] leading-4 text-main'>{t('press_the_day_to_show_your_availability')}</p>
                            </div>
                        </div>

                        {/* days buttons */}
                        <div className=' flex flex-row gap-3'>
                            <DayButton title={t('saturday')} isSelected={day === 'saturday'} onClick={() => setDay('saturday')} />
                            <DayButton title={t('sunday')} isSelected={day === 'sunday'} onClick={() => setDay('sunday')} />
                            <DayButton title={t('monday')} isSelected={day === 'monday'} onClick={() => setDay('monday')} />
                            <DayButton title={t('tuesday')} isSelected={day === 'tuesday'} onClick={() => setDay('tuesday')} />
                            <DayButton title={t('wednesday')} isSelected={day === 'wednesday'} onClick={() => setDay('wednesday')} />
                            <DayButton title={t('thursday')} isSelected={day === 'thursday'} onClick={() => setDay('thursday')} />
                            <DayButton title={t('friday')} isSelected={day === 'friday'} onClick={() => setDay('friday')} />
                        </div>

                        {/* working time */}
                        <div className='flex flex-col gap-5 bg-input-second py-7 px-6 rounded-xl'>
                            {data.workTimes?.map((time, index)=><WorkTime key={index} day={day} item={time}/>)}
                            <AddWorkTime day={day}/>
                        </div>
                    </div>
)
}
